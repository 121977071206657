.mini-button {
  margin: 0rem;
  border: none;
  background: none;
  color: #fff;
}

.mini-button:hover {
  cursor: pointer;
  opacity: 0.6;
}

#config.mini-button{
  margin:0.7;
  margin-right: 0;
  background-color: #fff;
  color:#5344c1;
  border:none;
  border-radius: 20%;
  padding: 2%;
  padding-top:3%;
}