.list-item-options {
  position: absolute;
  background-color: #d8d3ff;
  display: flex;
  flex-direction: column;
  border-radius: .3em;
  width: 7em;
  color: #5344c1;
  z-index: 20;
  visibility: hidden;
}

.list-item-options.visible {
  visibility: visible;
}