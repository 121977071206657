.widget-container {
  padding: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.widget-container-2 {
  padding: 0em 3em 0em 2.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Date-widget {
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #5344c1;
  
}
.Date-widget-create-reunion{
  font-size: 0.9rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #5344c1;
}

.Date-widget-tag {
  margin-right: 1rem;
  font-size: 0.9rem;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #4a4a4a;
}

.list-item-icon-marker{
  margin-left: 1em;
}

.widget-container-mobile {
  padding: .1em;
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: space-between;
}