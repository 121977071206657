.anotation-instances-container {
  display: flex;
  flex-direction: column;
}

.marker-data .marker-context {
  font-size: 0.8em;
}

.marker-data .marker-name {
  font-size: 0.8em;
  font-weight: bold;
}

.marker-data .marker-description {
  font-size: 0.8em;
  color: black;
}

.marker-data {
  margin-left: 1em;
}

.marker-Date-widget {
  font-size: 0.8rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #5344c1;
}

.marker-Date-widget-tag {
  margin-right: auto;
  font-size: 0.9rem;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #4a4a4a;
}

.general-button-marker {
  display: flex;
  align-items: center;
  writing-mode: vertical-rl;
  border-width: 0em;
  background-color: transparent;
  font-weight: bold;
  font-size: 2em;
  padding-top: 0em !important;
  height: auto;
}

.instances-container {
  display: flex;
  flex-direction: column;
  border-bottom: 0.1em solid #5344c1;
}

.hidden {
  display: none;
}

.yellow {
  color: #f5a623 !important;
}

.purple {
  color: #bd10e0 !important;
}

.lightblue {
  color: #4a90e2 !important;
}

.red {
  color: #f00 !important;
}

.lightgreen {
  color: #46c7aa !important;
}

.orange {
  color: #e36b00 !important;
}

.grey {
  color: #8383839f !important;
}

.pink {
  color: #ff0fa9 !important;
}

.darkgreen {
  color: #00655a !important;
}

.darksalmon {
  color: darksalmon !important;
}

.lightslategrey {
  color: lightslategrey !important;
}

.filter {
  filter: invert(92%) sepia(0%) saturate(2343%) hue-rotate(150deg)
    brightness(104%) contrast(102%);
}

.img-yellow {
  background-color: #f5a623;
}

.img-purple {
  background-color: #bd10e0;
}

.img-lightblue {
  background-color: #4a90e2;
}

.img-red {
  background-color: #f00;
}

.img-lightgreen {
  background-color: #46c7aa;
}
.img-orange {
  background-color: #e36b00;
}

.img-pink {
  background-color: #ff0fa9;
}

.img-darkgreen {
  background-color: #00655a;
}

.img-darksalmon {
  background-color: darksalmon;
}

.instances-container-green {
  display: flex;
  flex-direction: column;
  border-bottom: 0.1em solid #67b017;
}

.hidden {
  display: none;
}

.yellow {
  color: #f5a623 !important;
}

.purple {
  color: #bd10e0 !important;
}

.lightblue {
  color: #4a90e2 !important;
}

.red {
  color: #f00 !important;
}

.lightgreen {
  color: #46c7aa !important;
}

.orange {
  color: #e36b00 !important;
}

.pink {
  color: #ff0fa9 !important;
}

.darkgreen {
  color: #00655a !important;
}

.darksalmon {
  color: darksalmon !important;
}

.lightslategrey {
  color: lightslategrey !important;
}

.filter {
  filter: invert(92%) sepia(0%) saturate(2343%) hue-rotate(150deg)
    brightness(104%) contrast(102%);
}

.img-yellow {
  background-color: #f5a623;
}

.img-purple {
  background-color: #bd10e0;
}

.img-lightblue {
  background-color: #4a90e2;
}

.img-red {
  background-color: #f00;
}

.img-lightgreen {
  background-color: #46c7aa;
}
.img-orange {
  background-color: #e36b00;
}

.img-pink {
  background-color: #ff0fa9;
}

.img-darkgreen {
  background-color: #00655a;
}

.img-darksalmon {
  background-color: darksalmon;
}

.img-lightslategrey {
  background-color: lightslategrey;
}
