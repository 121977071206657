.list-item.project h3 {
  padding-left: 1em;
  margin-bottom: .3em;
  color: #5344c1;
}

.list-item.project p {
  padding-left: 1em;
  margin-top: .3em;
  color: #5344c1;
}

.product .button {
  margin-right: 1em;
  margin-bottom: 1em;
  align-self: flex-end;
  color: white;
  background-color: #5344c1;
  border-color: #5344c1;
}