.redico-header {
  width: auto;
  height: 4%;
  border-bottom: 0.1em solid black;
  display: flex;
  flex-direction: row;
  padding-top: 0.5em;
  flex-shrink: 1;
}

.redico-header h2 {
  margin: 0;
  margin-left: 1em;
}

.redico-header-right-buttons {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.fill-screen {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
}

.content-content {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.fill-screen::-webkit-scrollbar {
  display: none;
}

.mobile-title{
  display: flex;
  color: #5344c1;
  border-top: white 5px solid;
  text-align: center;
  font-size: xx-large;
  font-weight: bold;
  margin-left: 2%;
  padding: 1%;
}

.mobile-title svg{
  display: flex;
  justify-self: center;
  margin-right: 5%;
  filter: invert(26%) sepia(59%) saturate(2445%) hue-rotate(234deg) brightness(87%) contrast(91%);
}

.mobile-content-content {
  width: auto;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.button.move-to-bottom {
border-width: 0em;
display: flex;
align-items: center;
justify-content: center;
color: #5344c1;
padding: 0em;
margin: 0rem 0rem;
border-top-width: 0.1em;
background-color: #d8d3ff;
height: 50%;
}

.move-to-bottom-mobile svg {
transform: rotate(180deg);
filter: invert(28%) sepia(25%) saturate(3133%) hue-rotate(222deg)
  brightness(103%) contrast(104%);
}

.move-to-bottom-mobile.color svg {
transform: rotate(180deg);
filter: invert(100%) sepia(91%) saturate(38%) hue-rotate(254deg)
  brightness(110%) contrast(110%);
}