a {
  text-decoration: none;
}

.org-list-header {
  padding-bottom: .5em;
  font-size: 2em;
  cursor: context-menu;
  font-weight: bold;
  text-align: center;
  color: #5344c1;
}