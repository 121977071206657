.item-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #5344c1;
  padding: .2em;
  border-radius: .7em;
  width: 5em;
  position: relative;
  left: -3em;
  top: -1em;
}