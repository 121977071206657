.project-page {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.project-page .project-header {
  border: 0.1em solid #5344c1;
  /* border-bottom-width: 0; */
  background-color: #d8d3ff;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.project-page .project-content {
  overflow: hidden;
  height: 100vh;
  border: 0.1em solid #5344c1;
  border-top-width: 0;
  display: flex;
  flex-direction: row;
}

.project-header .project-logo {
  width: 5em;
  height: 5em;
  margin-left: 1.5em;
  border: 0.1em solid #5344c1;
}

.project-header .project-data {
  margin-left: 1.5em;
  color: #4a4a4a;
}

.project-header .button {
  writing-mode: vertical-rl;
  border-width: 0em;
  background-color: transparent;
  color: #5344c1;
  font-weight: bold;
  font-size: 2em;
  padding-top: 2em;
  height: auto;
}

.project-data .project-context {
  font-size: 0.8em;
}

.project-data .project-context b {
  color: #5344c1;
}

/* .project-data .project-name {
  font-size: 1.5em;
  font-weight: bold;
  color: #5344c1;
} */

.project-options-button .button {
  font-size: 2.5em;
  border-color: transparent;
  background-color: transparent;
  position: relative;
  border-width: 0;
}

.project-options-button {
  border-color: transparent;
  background-color: transparent;
  position: relative;
  border-width: 0;
}

.project-options {
  writing-mode: vertical-lr;
  position: absolute;
  background-color: #d8d3ff;
  z-index: 1;
  left: 93%;
  top: 12%;
  width: 5%;
  -webkit-transform: rotate(270deg);
}

.project-options.hidden {
  visibility: hidden;
}

.project-options.active {
  visibility: visible;
}

.project-options .button {
  font-size: 1em;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.959);
}

.project .button:active {
  background-color: #d0caff;
  border-width: 0;
}

.widget-container{
  padding: auto;
}

.project-page-mobile .project-content-mobile {
  overflow: hidden;
  height: 100%;
  border: 0.1em solid #5344c1;
  border-top-width: 0;
  display: flex;
  flex-direction: column;
}

.project-page-mobile{
  display: flex;
  flex-direction: column;
  margin: 0vh 0vh;
}

.project-header-mobile{
  background-color: #d8d3ff;
}

.project-page .project-header-mobile {
  border: 0.1em solid #5344c1;
  /* border-bottom-width: 0; */
  background-color: #d8d3ff;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.project-header-mobile .project-logo {
  width: 5em;
  height: 5em;
  margin-left: 1em;
  border: 0.1em solid #5344c1;
}

.project-header-mobile .project-data {
  margin-left: 1.5em;
  color: #4a4a4a;
}

.project-header-mobile .button {
  writing-mode: vertical-rl;
  border-width: 0em;
  background-color: transparent;
  color: #5344c1;
  font-weight: bold;
  font-size: 2em;
  padding-top: 2em;
  height: auto;
}