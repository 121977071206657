.list-item.attached-file {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  color: #5344c1;
}

.list-item.attached-file .spacer {
  flex-grow: 1;
}

.list-item.attached-file div:first-child {
  margin: 0em 1em;
}

.attached-file.description h3 {
  margin-bottom: 0.2em;
}

.attached-file.description p {
  margin-top: 0em;
}

.list-item.attached-file div:last-child {
  margin-right: 1em;
}

.list-item.attached-file:last-child {
  border-bottom-width: 0em;
}

.attached-file-button {
  border-width: 0;
  background-color: transparent;
  height: 2em;
  width: 2.5em;
  padding: 0;
  padding-top: .5em;
  border-radius: 50%;
}

.attached-file-button:active {
  background-color: #d8d3ff;
}