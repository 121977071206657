.frame {
  display: flex;
  height: 95%;
  width: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* margin-left: 3em; */
}

.modules {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding: 2.5em auto;
  height: 100%;
  width: 98%;
  aspect-ratio: 2/1;
  flex-shrink: 1;
}

.modules svg {
  display: flex;
  filter: invert(26%) sepia(59%) saturate(2445%) hue-rotate(234deg)
    brightness(87%) contrast(91%);
  min-height: 4rem;
  width: auto;
  margin: 6% 6%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.proyects-module {
  min-width: 24.8%;
  width: 13.5em;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-area: 1 / 1 / 5 / 2;
  border: solid 1px #5344c1;
  background-color: #fff;
}

.admin-module {
  min-width: 24.8%;
  width: 13.5em;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-area: 1 / 2 / 5 / 3;
  border: solid 1px #5344c1;
  background-color: #fff;
}

.meeting-module {
  min-width: 24.8%;
  width: 13.5em;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-area: 1 / 2 / 5 / 3;
  border: solid 1px #5344c1;
  background-color: #fff;
}

.notes-module {
  min-width: 24.8%;
  width: 13.5em;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-area: 1 / 3 / 5 / 4;
  border: solid 1px #5344c1;
  background-color: #fff;
}

.lessons-module {
  min-width: 24.8%;
  width: 13.5em;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-area: 1 / 4 / 5 / 5;
  border: solid 1px #5344c1;
  background-color: #fff;
}

.config-module {
  min-width: 24.8%;
  width: 13.5em;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-area: 5 / 1 / 9 / 2;
  border: solid 1px #5344c1;
  background-color: #fff;
}

.payments-module {
  min-width: 24.8%;
  width: 13.5em;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-area: 5 / 2 / 9 / 3;
  border: solid 1px #5344c1;
  background-color: #fff;
}

.blank-module1 {
  min-width: 24.8%;
  width: 13.5em;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-area: 5 / 3 / 9 / 4;
  border: solid 1px #5344c1;
  background-color: #fff;
}

.blank-module2 {
  min-width: 24.8%;
  width: 13.5em;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-area: 5 / 4 / 9 / 5;
  border: solid 1px #5344c1;
  background-color: #fff;
}

.module-name {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: large;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    color: #5344c1;
  }

.module-number {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: x-large;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #5344c1;
  }

.payment-state{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: x-large;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: red;

}

.payment-state svg{
    filter: invert(22%) sepia(74%) saturate(7486%) hue-rotate(356deg) brightness(98%) contrast(118%);
}

.payment-state-button{
    width: 10em;
    height: 3em;
    padding: .8em .8em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: small;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    text-align: center;    
    line-height: normal;
    color: white;
    background-color: red;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.module-button{
    width: 10em;
    height: 3em;
    padding: .8em .8em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: small;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    text-align: center;    
    line-height: normal;
    color: white;
    background-color: #5344c1; 
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

@media only screen and (max-width: 34em) {
  .main-content {
    height: 100%;
  }
  .frame {
    justify-content: flex-start;
    height: 100%;
  }

  .modules {
    grid-row-gap: 0px;
    padding: 0em 0em;
    height: 60%;
    width: 100%;
  }

  .modules svg {
    width: 30%;
    margin: 0%;
  }

  .module-name {
    font-size: 0.85em;
    text-justify: distribute;
  }

  .module-number {
    font-size: 0.85em;
  }

  .payment-state {
    font-size: 0.85em;
  }

  .module-button {
    font-size: 0.6em;
  }

  .proyects-module {
    width: 45%;
    margin: 0.8%;
    border-radius: 4%;
  }

  .admin-module {
    width: 45%;
    margin: 0.8%;
    border-radius: 4%;
  }

  .meeting-module {
    width: 45%;
    margin: 0.8%;
    border-radius: 4%;
  }

  .notes-module {
    width: 45%;
    margin: 0.8%;
    border-radius: 4%;
  }

  .lessons-module {
    width: 45%;
    margin: 0.8%;
    border-radius: 4%;
  }

  .config-module {
    width: 45%;
    margin: 0.8%;
    border-radius: 4%;
  }

  .payments-module {
    width: 45%;
    margin: 0.8%;
    border-radius: 4%;
  }
}

@media only screen and (max-width: 69.6em) {
  .blank-module1 {
    display: none;
  }

  .blank-module2 {
    display: none;
  }
}
