.add-modal {
    position: fixed;
    z-index: 9;
    bottom: 0;
    top: -4.5em;
    left: 7.1em;
    right:-7.1em;
    background-color: rgba(13,0,107,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-behavior: unset;
    padding-left: 0em;
    padding-top: 0em;
    padding-bottom: 0em;
}

.modal-open {
    visibility: visible;
}

.project-maintainer-modal{
    z-index: 9;
    background-color: white;
    border-radius: 10px;
}

.modal-content {
    display: flex;
    flex-direction: column;
}

.modal-button {
    margin-right: -1em;
    margin-top: -1em;
    position: absolute;
    align-self: flex-end;
}

.modal-button svg {
    width: 1.6em;
    height: 1.6em;
    cursor: pointer;
    border-radius: 50%;
    border-color: #d8d3ff;
    background-color: red;
    border-width: .2em;
    border-style: solid;
    z-index: -1;
}

.project-maintainer-header{
    background-color: #d8d3ff;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: .5em .5em;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.project-logo{
    width: 4em;
    height: 4em;
    border: 0.1em solid #5344c1;
    float: left;
    margin: 1em 1em;
}

.project-data{
    margin-left: 1.5em;
    color: #4a4a4a;
    margin: 1em 1em;
    vertical-align: middle;
}

.project-name {
    font-size: 1.5em;
    font-weight: bold;
    color: #5344c1;
}

.project-context {
    font-size: 0.8em;
    color: #5344c1;
}

.project-context.green {
    color: #67b017;
}

.project-description{
    font-size: 0.8em;
    color: black;
}

.project-maintainer-list-content {
    padding: 1rem 1rem;
    padding-right: 1.5rem;
    height: 20rem;
    overflow-y: scroll;
    flex-grow: 1;
    background-color: #ffffff;


}

.project-maintainer-list-content::-webkit-scrollbar {
    display: none;
  }

.list-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    height: 92%;
    margin: 0.3em 0.3em;
    min-height: 15em;
    overflow-y: scroll;
    background-color: #ffffff;
}

.list-header {
    height: 3em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 0.1em solid #5344c1;
    background-color: #5344c1;
    border-bottom-width: 0em;
    color: #ffffff;
    padding: 0em 1em;
}

.list-content {
    padding: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    flex-grow: 1;
    background-color: #ffffff;
    border: 0.1em solid #5344c1;
    opacity: 1;
}

.list-item-adm {
    border-bottom: 0.1em solid #5344c1;
    padding: 1em;
    display: flex;
    height: 5em;
    width: 100%;
    padding: 0em 0em 0em 0em;
    align-items: center;
    justify-content: space-between;
}

.add-maintainer-button-box {
    width: 100%;
    margin-top: -1.3rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
}

.add-maintainer-button {
    height: 0.96rem;
    padding: 0.5rem 0.564rem 1.2rem 0.5rem;
    border-radius: 5px;
    border: solid 1px #fff;
    background-color: #5344c1;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.01px;
    text-align: center;
    color: #fff;
    cursor: pointer;
}