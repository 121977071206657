.home-header{
    height: 4em;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: .1em solid black;
    position: absolute;
    top: 0em;
    margin-left: -3em;
}

.title {
    width: 10%;
    vertical-align: center;
    text-align: center;
    cursor: pointer;
    margin-top: 0.3em;
}

.right-buttons{
    margin-left: auto;
    padding: 1em 1em;
    padding-right: 0em;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}