.display-info-user{
    column-count: 3;
    column-gap: 40px;/*ancho minimo columna, sirve para responsive*/ 
    column-width:150px;
}

img{
    max-width:100%;
    max-height:40vh;
}

.profile-items{
    font-size: small;
    color:#5344c1;
}

.profile-notice{
    text-align: center;
    color:rgb(170, 0, 0);
    font-size: x-small;
}

.input-box{
    width:96%;
}

.submit-info-button{
    margin: 0em;
    /* width: fit-content; */
    /* min-width: 1em; */
    /* padding-left: 1em; */
    /* padding-right: 1em; */
    /* padding-top: .5em; */
    /* padding-bottom: 0em; */
    padding: .10em .10em;
    margin: .5em .5em;
    /* height: auto; */
    line-height: .5em;
    /* border: .1em solid black; */
    border-radius: .5em;
    background-color: #5344c1;
    color: white;
    text-align: center;
    align-content: center;
    align-items:center;
    vertical-align: middle;
    cursor: pointer;
    position: relative;
    font-size: small;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border-color:#5344c1;
    box-shadow: none;
    width: 95%;
}