.mini-side-panel {
  min-width: 6.39rem;
  width: auto;
  height: 100%;
  border: 0em solid #5344c1;
  border-right-width: 0.1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: start;
  margin-bottom: 0rem;
}

.mini-side-panel .button {
  width: 100%;
  height: 100%;
  border-color: #5344c1;
  background-color: #fff;
  border-width: 0em;
  border-bottom-width: 0.1em;
  padding: 0em;
  margin-bottom: 0rem;
}

.mini-side-panel .svg-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mini-side-panel .svg-container svg {
  margin: 1em;
  filter: invert(28%) sepia(25%) saturate(3133%) hue-rotate(222deg)
    brightness(103%) contrast(104%);
}

.mini-side-panel .svg-container.selected svg {
  width: auto;
  margin: 1em;
  stroke: #fff;
  filter: invert(100%) sepia(91%) saturate(38%) hue-rotate(254deg)
    brightness(110%) contrast(110%);
}
.mini-side-panel .svg-container.selected {
  background-color: #5344c1;
}

.mini-side-panel .svg-container.selected.yellow {
  background-color: #f5a623;
}
.mini-side-panel .svg-container.selected.purple{
  background-color: #bd10e0;
}
.mini-side-panel .svg-container.selected.lightblue{
  background-color: #4a90e2;
}
.mini-side-panel .svg-container.selected.red{
  background-color: #f00;
}
.mini-side-panel .svg-container.selected.lightgreen{
  background-color: #46c7aa;
}
.mini-side-panel .svg-container.selected.orange{
  background-color: #e36b00;
}
.mini-side-panel .svg-container.selected.pink{
  background-color: #ff0fa9;
}
.mini-side-panel .svg-container.selected.darkgreen{
  background-color: #00655a;
}
.mini-side-panel .svg-container.selected.darksalmon{
  background-color: darksalmon;
}
.mini-side-panel .svg-container.selected.lightslategrey{
  background-color: lightslategrey;
}

.mini-side-panel .button.move-to-bottom {
  border-width: 0em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5344c1;
  padding: 0em;
  margin: 0rem 0rem;
  border-top-width: 0.1em;
  background-color: #d8d3ff;
  height: 50%;
}

.mini-side-panel .button.move-to-bottom.yellow{
  background-color: #f5a623;
}
.mini-side-panel .button.move-to-bottom.purple{
  background-color: #bd10e0;
}
.mini-side-panel .button.move-to-bottom.lightblue{
  background-color: #4a90e2;
}
.mini-side-panel .button.move-to-bottom.red{
  background-color: #f00;
}
.mini-side-panel .button.move-to-bottom.lightgreen{
  background-color: #46c7aa;
}
.mini-side-panel .button.move-to-bottom.orange{
  background-color: #e36b00;
}
.mini-side-panel .button.move-to-bottom.pink{
  background-color: #ff0fa9;
}
.mini-side-panel .button.move-to-bottom.darkgreen{
  background-color: #00655a;
}
.mini-side-panel .button.move-to-bottom.darksalmon{
  background-color: darksalmon;
}
.mini-side-panel .button.move-to-bottom.lightslategrey{
  background-color: lightslategrey;
}

.move-to-bottom svg {
  transform: rotate(180deg);
  filter: invert(28%) sepia(25%) saturate(3133%) hue-rotate(222deg)
    brightness(103%) contrast(104%);
}

.move-to-bottom.color svg {
  transform: rotate(180deg);
  filter: invert(100%) sepia(91%) saturate(38%) hue-rotate(254deg)
    brightness(110%) contrast(110%);
}

/*Mobile*/

.mini-side-panel-mobile {
  min-width: 6.39rem;
  width: 100%;
  height: 10%;
  border: 0em solid #5344c1;
  border-top-width: .1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: start;
  margin-bottom: 0rem;
}

.mini-side-panel-mobile .button {
  width: 100%;
  height: 100%;
  border-color: #5344c1;
  background-color: #fff;
  border-width: 0em;
  border-bottom-width: 0.1em;
  padding: 0em;
  margin-bottom: 0rem;
}

.mini-side-panel-mobile .svg-container {
  display: flex;
  height: 7vh;
  justify-content: center;
  align-items: center;
}

.mini-side-panel-mobile .svg-container svg {
  margin: 1em;
  filter: invert(28%) sepia(25%) saturate(3133%) hue-rotate(222deg)
    brightness(103%) contrast(104%);
}

.mini-side-panel-mobile .svg-container.selected svg {
  margin: 1em;
  stroke: #fff;
  filter: invert(100%) sepia(91%) saturate(38%) hue-rotate(254deg)
    brightness(110%) contrast(110%);
}
.mini-side-panel-mobile .svg-container.selected {
  background-color: #5344c1;
}

.mini-side-panel-mobile .svg-container.selected.yellow {
  background-color: #f5a623;
}
.mini-side-panel-mobile .svg-container.selected.purple{
  background-color: #bd10e0;
}
.mini-side-panel-mobile .svg-container.selected.lightblue{
  background-color: #4a90e2;
}
.mini-side-panel-mobile .svg-container.selected.red{
  background-color: #f00;
}
.mini-side-panel-mobile .svg-container.selected.lightgreen{
  background-color: #46c7aa;
}
.mini-side-panel-mobile .svg-container.selected.orange{
  background-color: #e36b00;
}
.mini-side-panel-mobile .svg-container.selected.pink{
  background-color: #ff0fa9;
}
.mini-side-panel-mobile .svg-container.selected.darkgreen{
  background-color: #00655a;
}
.mini-side-panel-mobile .svg-container.selected.darksalmon{
  background-color: darksalmon;
}
.mini-side-panel-mobile .svg-container.selected.lightslategrey{
  background-color: lightslategrey;
}

.mini-side-panel-mobile .button.move-to-bottom.yellow{
  background-color: #f5a623;
}
.mini-side-panel-mobile .button.move-to-bottom.purple{
  background-color: #bd10e0;
}
.mini-side-panel-mobile .button.move-to-bottom.lightblue{
  background-color: #4a90e2;
}
.mini-side-panel-mobile .button.move-to-bottom.red{
  background-color: #f00;
}
.mini-side-panel-mobile .button.move-to-bottom.lightgreen{
  background-color: #46c7aa;
}
.mini-side-panel-mobile .button.move-to-bottom.orange{
  background-color: #e36b00;
}
.mini-side-panel-mobile .button.move-to-bottom.pink{
  background-color: #ff0fa9;
}
.mini-side-panel-mobile .button.move-to-bottom.darkgreen{
  background-color: #00655a;
}
.mini-side-panel-mobile .button.move-to-bottom.darksalmon{
  background-color: darksalmon;
}
.mini-side-panel-mobile .button.move-to-bottom.lightslategrey{
  background-color: lightslategrey;
}

.button-purple{
  background-color: #d8d3ff;
  border-color: #5344c1;
}

.button.move-to-bottom {
  border-width: 0em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5344c1;
  padding: 0em;
  margin: 0rem 0rem;
  border-top-width: 0.1em;
  background-color: #d8d3ff;
  height: 50%;
}

.move-to-bottom-mobile svg {
  transform: rotate(180deg);
  filter: invert(28%) sepia(25%) saturate(3133%) hue-rotate(222deg)
    brightness(103%) contrast(104%);
}

.move-to-bottom-mobile.color svg {
  transform: rotate(180deg);
  filter: invert(100%) sepia(91%) saturate(38%) hue-rotate(254deg)
    brightness(110%) contrast(110%);
}
