.list-container {
  width: 100%;
  height: 92%;
  display: flex;
  flex-direction: column;
}

.list-container-active {
  max-width: 91.5%;
  min-width: 80%;
  padding-left: 0rem;
  padding-right: -7rem;
}

.list-header {
  height: 3em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 0.1em solid #5344c1;
  background-color: #5344c1;
  border-bottom-width: 0em;
  color: #ffffff;
  padding: 0em 1em;
}

.list-header .button {
  background-color: transparent;
  border-width: 0em;
  color: #5344c1;
  font-weight: bold;
}

#green.list-header {
  border: 0.1em solid #67b017;
  background-color: #67b017;
}

#green.list-header .button {
  color: #67b017;
}

#green.list-header .search-input {
  border-color: #67b017;
  color: #67b017;
}

#green.list-item-adm.project {
  border-bottom: 0.1em solid #67b017;
}

#yellow.list-header {
  background-color: #f5a623;
}

#yellow.list-header .button {
  color: #f5a623;
}

#yellow.list-header .search-input {
  border-color: #f5a623;
  color: #f5a623;
}

#purple.list-header {
  background-color: #bd10e0;
}

#purple.list-header .button {
  color: #bd10e0;
}

#purple.list-header .search-input {
  border-color: #bd10e0;
  color: #bd10e0;
}

#lightblue.list-header {
  background-color: #4a90e2;
}

#lightblue.list-header .button {
  color: #4a90e2;
}

#lightblue.list-header .search-input {
  border-color: #4a90e2;
  color: #4a90e2;
}

#red.list-header {
  background-color: #f00;
}

#red.list-header .button {
  color: #f00;
}

#red.list-header .search-input {
  border-color: #f00;
  color: #f00;
}

#lightgreen.list-header {
  background-color: #46c7aa;
}

#lightgreen.list-header .button {
  color: #46c7aa;
}

#lightgreen.list-header .search-input {
  border-color: #46c7aa;
  color: #46c7aa;
}

#orange.list-header {
  background-color: #e36b00;
}

#orange.list-header .button {
  color: #e36b00;
}

#orange.list-header .search-input {
  border-color: #e36b00;
  color: #e36b00;
}

#pink.list-header {
  background-color: #ff0fa9;
}

#pink.list-header .button {
  color: #ff0fa9;
}

#pink.list-header .search-input {
  border-color: #ff0fa9;
  color: #ff0fa9;
}

#darkgreen.list-header {
  background-color: #00655a;
}

#darkgreen.list-header .button {
  color: #00655a;
}

#darkgreen.list-header .search-input {
  border-color: #00655a;
  color: #00655a;
}

#darksalmon.list-header {
  background-color: darksalmon;
}

#darksalmon.list-header .button {
  color: darksalmon;
}

#darksalmon.list-header .search-input {
  border-color: darksalmon;
  color: darksalmon;
}

#lightslategrey.list-header {
  background-color: lightslategrey;
}

#lightslategrey.list-header .button {
  color: lightslategrey;
}

#lightslategrey.list-header .search-input {
  border-color: lightslategrey;
  color: lightslategrey;
}

.list-title h3 {
  margin: 0px;
}

.left-line {
  margin-left: 1em;
  border-left-width: 0.1em;
  max-height: 1.5em;
  border-left-style: solid;
  border-left-color: #ffffff;
  padding-left: 1em;
}

.left-line .attached-file-icon svg {
  filter: none;
}

.left-line-white {
  margin-left: 1em;
  border-left-width: 0.1em;
  max-height: 1.5em;
  border-left-style: solid;
  border-left-color: #ffffff;
  padding-left: 1em;
}

.config-left-line {
  margin-left: 1em;
  border-left-width: 0.1em;
  border-left-style: solid;
  border-left-color: #ffffff;
  padding-left: 0.2em;
}

.help-icon {
  display: flex;
  padding-left: 0.5em;
}
.data-left-line {
  margin-left: 1em;
  border-left-width: 0.1em;
  border-left-style: solid;
  border-left-color: #5344c1;
  padding-left: 1em;
  height: 3em;
}

.content-icon-container {
  display: flex;
}

.left-line svg {
  filter: invert(100%) sepia(91%) saturate(38%) hue-rotate(254deg)
    brightness(110%) contrast(110%);
  height: 1.3em;
}

.left-line-white svg {
  height: 1.3em;
}

.right-line {
  margin-right: 10em;
  border-right-width: 0.1em;
  border-right-style: solid;
  border-right-color: #5344c1;
  padding-right: 1em;
}

.list-content {
  padding: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-grow: 1;
  background-color: #ffffff;
  border: 0.1em solid #5344c1;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  opacity: 1;
}

.list-content::-webkit-scrollbar {
  display: none;
}

.list-content-3-item {
  padding: 0;
  overflow-y: scroll;
  flex-grow: 1;
  height: 18em;
  background-color: #ffffff;
  border: 0.1em solid #5344c1;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  opacity: 1;
}

.list-content-3-item::-webkit-scrollbar {
  display: none;
}

.list-content-4 {
  padding: 0;
  overflow-y: scroll;
  flex-grow: 1;
  background-color: #ffffff;
  border: 0.1em solid #5344c1;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  opacity: 1;
  height: 18em;
}

.list-content-4::-webkit-scrollbar {
  display: none;
}

.list-content-3-item-green {
  padding: 0;
  overflow-y: scroll;
  flex-grow: 1;
  background-color: #ffffff;
  border: 0.1em solid #67b017;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  opacity: 1;
  height: 18em;
}

.list-content-3-item-green::-webkit-scrollbar {
  display: none;
}

.list-item {
  border-bottom: 0.1em solid #5344c1;
  height: 8em;
  padding: 0em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.list-button {
  margin-top: 0em;
  margin-bottom: 0em;
  margin-right: 0em;
  margin-left: 1em;
}

.move-right {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
}

.move-back {
  color: #5344c1;
  font-size: xx-large;
  margin-top: auto;
  padding: .9em 0em;
  border-width: 0em;
  background-color: white;
}

.project-left-content {
  height: 8em;
  padding: 0em;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.project-right-content {
  height: 8em;
  padding: 0em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  /* min-width: 12rem; Si algo se ListWidgetItem en project o etc se desconfigura es por esto */
}

.date-box {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
}

.date-box-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 9em;
}

.date-box-container .svg-container svg {
  margin: 1em;
  filter: invert(28%) sepia(25%) saturate(3133%) hue-rotate(222deg)
    brightness(103%) contrast(104%);
}

.date-box-container .svg-container {
  border-width: 0em;
  background-color: transparent;
}
.date-box-date {
  color: #5344c1;
  font-size: 1.2rem;
}
.date-box-text {
  color: #4a4a4a;
  font-size: 1.2rem;
}

.list-item.project .project-button {
  height: 100%;
  padding: 0em 1em;
  border-color: #5344c1;
  background-color: #d8d3ff;
  color: #5344c1;
  border-width: 0em 0em 0em 0.1em;
}

.list-item-adm.project .project-button {
  padding: 0em 1em;
  height: 5em;
  border-color: #5344c1;
  background-color: #d8d3ff;
  color: #5344c1;
  border-width: 0em 0em 0em 0.1em;
}

.general-button {
  writing-mode: vertical-rl;
  border-width: 0em;
  background-color: transparent;
  color: #5344c1;
  font-weight: bold;
  font-size: 2em;
  padding-top: 0em;
  height: auto;
}

.nothing {
  writing-mode: vertical-rl;
  border-width: 0em;
  background-color: transparent;
  color: #5344c1;
  font-weight: bold;
  font-size: 2em;
  padding-top: 0em;
  height: auto;
  margin-left: 0.5em;
}

.list-item-icon svg {
  margin-left: 1em;
  filter: invert(28%) sepia(25%) saturate(3133%) hue-rotate(222deg)
    brightness(103%) contrast(104%);
}

.list-item-icon.green svg {
  border-color: #67b017;
  filter: invert(67%) sepia(59%) saturate(4069%) hue-rotate(51deg) brightness(99%) contrast(82%);
}

.list-item-icon-2 svg {
  margin-left: 1.5em;
  margin-top: 1rem;
  margin-bottom: 0rem;
  filter: invert(28%) sepia(25%) saturate(3133%) hue-rotate(222deg)
    brightness(103%) contrast(104%);
}

.list-item.project .project-logo {
  width: 5em;
  height: 5em;
  margin-left: 2em;
  border: 0.1em solid #5344c1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e6952d;
}

.list-item.project .project-data {
  margin-left: 1em;
  color: #5344c1;
}

.list-info {
  display: flex;
  align-items: center;
}

.list-info-mobile {
  display: flex;
  align-items: center;
}

.list-info-mobile svg{
  filter: brightness(0) invert(1);
  padding: 15%;
}

.list-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.project-data .project-context {
  color: #5344c1;
  font-size: 0.8em;
}
.project-data .project-name {
  font-size: 1.5em;
  font-weight: bold;
  color: #5344c1;
}

.project-data .project-description {
  font-size: 0.8em;
  color: black;
}

.search-input {
  width: 95%;
  height: 1em;
  border-width: 0.1em;
  border-style: solid;
  border-color: #5344c1;
  border-radius: 0.5em;
  padding: 0.5em;
  font-size: 1em;
  color: #5344c1;
}

.search-input:focus {
  outline: none;
}

.search-box {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.search-box svg {
  height: 1.3;
}

#link_home {
  display: flex;
  flex-direction: row;
  background-color: rgb(201, 201, 201);
}

.projects-logo {
  width: 1.3rem;
  height: 1.4rem;
  margin: 0.5rem 0.7rem 0.3rem 0.5rem;
  filter: invert(88%) sepia(9%) saturate(281%) hue-rotate(201deg)
    brightness(110%) contrast(95%);
}

.projects-logo-2 {
  width: 1.3rem;
  height: 1.4rem;
  margin-left: 44.5rem;
  filter: invert(20%) sepia(87%) saturate(3676%) hue-rotate(247deg)
    brightness(72%) contrast(129%);
}

.consult-logo {
  width: 1.3rem;
  height: 1.1rem;
  margin: 0.08rem 0rem 0rem;
  border: solid 0.1rem #d8d3ff;
  filter: invert(88%) sepia(9%) saturate(281%) hue-rotate(201deg)
    brightness(110%) contrast(95%);
}

.lupa-logo {
  width: 1.3rem;
  height: 1.1rem;
  margin: 6rem 0rem 6rem 51.563rem;
  filter: invert(90%) sepia(39%) saturate(357%) hue-rotate(240deg)
    brightness(115%) contrast(96%);
}

.minor-major-logo {
  width: 1.3rem;
  height: 1.1rem;
  margin: 0.5rem 0rem 0.5rem 1rem;
  filter: invert(90%) sepia(39%) saturate(357%) hue-rotate(240deg)
    brightness(115%) contrast(96%);
}

.filter-logo {
  width: 1.3rem;
  height: 1.1rem;
  margin: 0.5rem 0rem 0.5rem 1rem;
  filter: invert(90%) sepia(39%) saturate(357%) hue-rotate(240deg)
    brightness(115%) contrast(96%);
}

.adm-logo {
  width: 1.6rem;
  height: 1.4rem;
  margin: 0rem 0.4rem 0.3rem 0rem;
  padding: 0.1rem 0.1rem 0.1rem;
  filter: invert(100%) sepia(7%) saturate(4433%) hue-rotate(175deg)
    brightness(103%) contrast(93%);
}

.amd-ind-logo {
  width: 1.6rem;
  height: 1.4rem;
  margin-left: 1em;
  filter: invert(20%) sepia(87%) saturate(3676%) hue-rotate(247deg)
    brightness(72%) contrast(129%);
}

#span_1 {
  width: 2rem;
  height: 1.2rem;
  margin: 0.4rem 0.2rem 0.3rem 0.1rem;
  font-family: Roboto;
  font-size: 0.9rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.002rem;
  text-align: center;
  color: #fff;
}

#span_2 {
  width: 2rem;
  height: 1.7rem;
  margin: 0.7rem 0.2rem 0rem 0.001rem;
  font-family: Roboto;
  font-size: 0.9rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.002rem;
  text-align: center;
  color: #fff;
}

.Rectangle {
  width: 5.75rem;
  height: 1.75rem;
  margin: 0.2rem 1rem 0.3rem 0.3rem;
  padding: 0.18rem 0.4rem 0.3rem;
  border-radius: 0.5rem;
  background-color: #fff;
  color: #5344c1;
}

.ADD {
  width: 2rem;
  height: 2rem;
  margin: 1rem 0.2rem 0.2rem 0.01rem;
  font-family: Roboto;
  font-size: 0.9rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01rem;
  color: #5344c1;
}

.user-name {
  width: 14.5rem;
  height: 0.2rem;
  margin: 0.2rem 4.5rem 1rem 0.5rem;
  font-family: Roboto;
  font-size: 0.9rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.02rem;
  color: #4a4a4a;
}

.Asign {
  font-family: Roboto;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.001rem;
  text-align: right;
  color: #4a4a4a;
}

.Asign-date {
  letter-spacing: 0.001rem;
  text-align: right;
}

.user-context {
  margin-left: 1rem;
  padding: 0em 1em 3em 0em;
  height: 0rem;
  font-family: Roboto;
  font-size: 0.75rem;
  line-height: normal;
  letter-spacing: 0.01rem;
  text-align: end;
  color: #5344c1;
}

.list-item-adm {
  border-bottom: 0.1em solid #5344c1;
  padding: 1em;
  display: flex;
  height: 5em;
  width: 100%;
  padding: 0em 0em 0em 0em;
  align-items: center;
  justify-content: space-between;
}

.list-item-adm.project.selection-list .project-left-content,
.list-item-adm.project.selection-list .project-right-content {
  height: 5em;
}

.list-item-adm.project.selection-list .project-right-content {
  visibility: hidden;
  width: 0;
}

.list-item-adm.project.selection-list .list-item-icon-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.list-item-adm.project.selection-list input,
.list-item-adm.project.selection-list svg {
  margin: 0;
  margin-left: 1em;
}

.title-pag {
  width: 18em;
  padding: 0rem 0rem 0rem 0rem;
}

.title-pag-projects {
  padding: 0rem 0.5rem 0rem 0.5rem;
}

.search-input-admin {
  width: 34%;
  height: 1em;
  margin: 0.5rem 0rem 0.5rem 22rem;
  border-width: 0.1em;
  border-style: solid;
  border-color: #5344c1;
  border-radius: 0.5em;
  padding: 0.5em;
  font-size: 1em;
  color: #5344c1;
}

.list-content-2 {
  padding: 1.2rem;
  overflow-y: scroll;
  flex-grow: 1;
  background-color: #ffffff;
  border: 0.1em solid #5344c1;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.list-content-2::-webkit-scrollbar {
  display: none;
}

.list-content-3 {
  padding: 1rem 1.5rem;
  height: 25rem;
  overflow-y: scroll;
  flex-grow: 1;
  background-color: #ffffff;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.list-content-3::-webkit-scrollbar {
  display: none;
}

.list-content-category {
  padding: 1em 1.5em;
  height: 25rem;
  overflow-y: scroll;
  flex-grow: 1;
  background-color: #ffffff;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  border-radius: 0em 0em 0.5em 0.5em;
}

.list-header-2 {
  height: 5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 0.1em solid #5344c1;
  background-color: #d8d3ff;
  border-bottom-width: 0em;
  color: #ffffff;
  padding: 0em 0.5em;
}

.Name-administer {
  width: auto;
  height: auto;
  margin-left: 0.8rem;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  font-family: Roboto;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.02px;
  color: #4a4a4a;
}

.amd-ind-logo-2 {
  margin-left: 0.3rem;
  width: 2rem;
  height: 2rem;
  filter: invert(20%) sepia(87%) saturate(3676%) hue-rotate(247deg)
    brightness(72%) contrast(129%);
}

.move-back-2 {
  background-color: #d8d3ff;
  border-color: #d8d3ff;
  color: #5344c1;
  margin-bottom: auto;
  margin-top: auto;
  margin-left: 0.001em;
  margin-right: 0rem;
  transform: rotate(180deg);
}

.user-list-right {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
}

.user-list-right .button {
  height: 100%;
  padding: 0em 1em 0em;
  border-color: #5344c1;
  background-color: #d8d3ff;
  color: #5344c1;
  border-width: 0em 0em 0em 0.1em;
}

.user-list-left {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
}

.list-counter {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.elegirAdms {
  height: 2rem;
  width: 1rem;
  align-items: center;
  margin-left: 1rem;
  margin-bottom: 1rem;
  padding: 0rem 10rem 100rem 100rem;
}

.svg-cerrar {
  height: 10px;
}
/* EditListWidget.js */

.left-right-line {
  margin-right: 1em;
  border-right-width: 0.1em;
  border-right-style: solid;
  border-right-color: #ffffff;
  padding-right: 1em;
}

.middle-actions {
  display: flex;
  height: 1.7em;
  flex-direction: row;
  align-items: left;
  color: #fff;
  margin-left: 1.5em;
  border-left-width: 0.1em;
  border-left-style: solid;
  padding-left: 1em;
}

.middle-actions button {
  margin: 0em 0.3em;
  padding: 0em 0em;
}

.right-list-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.right-list-actions button {
  margin: 0em 0.3em;
  padding: 0em 0em;
}

#clip {
  border-radius: 10%;
  padding: 0.1em 0.1em;
  background: #fff;
  color: #5344c1;
}

#clip:hover {
  cursor: pointer;
  opacity: 0.6;
}

@media only screen and (max-width: 48.5em) {
  .left-line {
    padding: 0em;
  }

  .list-header {
    padding: 0em 0.4em;
  }

  .list-info h3 {
    font-size: 1rem;
  }
  /* list content */
  .list-item {
    height: 8em;
    justify-content: space-between;
  }

  .list-item.project .project-button {
    height: 100%;
    padding: 0em 1em;
    border-color: #5344c1;
    background-color: #d8d3ff;
    color: #5344c1;
    border-width: 0em 0em 0em 0.1em;
  }

  .list-item.project .project-logo {
    width: auto;
    height: 8em;
    margin-left: 0em;
    border: 0.1em solid #5344c1;
    display: none;
    align-items: center;
    justify-content: center;
    color: #e6952d;
  }

  .project-left-content {
    height: 8em;
    width: 100%; /*Al quitarle el right-side, eoc 67%*/
    padding: 0em;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .date-box-date {
    color: #5344c1;
    font-size: 1rem;
  }

  .date-box-text {
    color: #4a4a4a;
    font-size: 1rem;
  }

  .project-data .project-name {
    font-size: 1em;
    color: #5344c1;
  }
}

@media only screen and (max-width: 30em) {
  .list-info h3 {
    display: none;
  }

  .left-line {
    border-left-width: 0;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
}

.list-item.project .import-project-logo {
  width: 5em;
  height: 5em;
  border: 0.1em solid #5344c1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e6952d;
  filter: invert(28%) sepia(25%) saturate(3133%) hue-rotate(222deg)
    brightness(103%) contrast(104%);
}

.elegirProject {
  height: 2rem;
  width: 1rem;
  margin-top: 1.3rem;
  background-color: #fff;
  color: #fff;
}

.list-item-icon-3 {
  display: flex;
  gap: 1rem;
  margin-left: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 0rem;
  /*filter: invert(28%) sepia(25%) saturate(3133%) hue-rotate(222deg)
    brightness(103%) contrast(104%);*/
}

.list-item-adm.project .yellow-second-button {
  padding: 0em 1em;
  height: 5em;
  border-color: #5344c1;
  background-color: #f5a623;
  color: #fff;
  border-width: 0em 0em 0em 0.1em;
}

.list-item-adm.project .purple-second-button {
  padding: 0em 1em;
  height: 5em;
  border-color: #5344c1;
  background-color: #bd10e0;
  color: #fff;
  border-width: 0em 0em 0em 0.1em;
}

.list-item-adm.project .lightblue-second-button {
  padding: 0em 1em;
  height: 5em;
  border-color: #5344c1;
  background-color: #4a90e2;
  color: #fff;
  border-width: 0em 0em 0em 0.1em;
}

.list-item-adm.project .red-second-button {
  padding: 0em 1em;
  height: 5em;
  border-color: #5344c1;
  background-color: #f00;
  color: #fff;
  border-width: 0em 0em 0em 0.1em;
}

.list-item-adm.project .lightgreen-second-button {
  padding: 0em 1em;
  height: 5em;
  border-color: #5344c1;
  background-color: #46c7aa;
  color: #fff;
  border-width: 0em 0em 0em 0.1em;
}

.list-item-adm.project .orange-second-button {
  padding: 0em 1em;
  height: 5em;
  border-color: #5344c1;
  background-color: #e36b00;
  color: #fff;
  border-width: 0em 0em 0em 0.1em;
}

.list-item-adm.project .pink-second-button {
  padding: 0em 1em;
  height: 5em;
  border-color: #5344c1;
  background-color: #ff0fa9;
  color: #fff;
  border-width: 0em 0em 0em 0.1em;
}

.list-item-adm.project .darkgreen-second-button {
  padding: 0em 1em;
  height: 5em;
  border-color: #5344c1;
  background-color: #00655a;
  color: #fff;
  border-width: 0em 0em 0em 0.1em;
}

.list-item-adm.project .darksalmon-second-button {
  padding: 0em 1em;
  height: 5em;
  border-color: #5344c1;
  background-color: darksalmon;
  color: #fff;
  border-width: 0em 0em 0em 0.1em;
}

.list-item-adm.project .lightslategrey-second-button {
  padding: 0em 1em;
  height: 5em;
  border-color: #5344c1;
  background-color: lightslategrey;
  color: #fff;
  border-width: 0em 0em 0em 0.1em;
}

.user-type {
  color: #5344c1;
  font-size: 1rem;
  text-align: left;
  width: 7rem;
}

.user-type span {
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #5344c1;
}
