.modal {
    position: fixed;
    z-index: 9;
    top: -5em;
    right: -7.3em;
    bottom: 0em;
    left: 6.3em;
    background-color: rgba(13,0,107,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-behavior: unset;
}

.modal-fixed {
    position: fixed;
    z-index: 9;
    top: -5em;
    right: -7.3em;
    bottom: 0em;
    left: 6.3em;
    background-color: rgba(13,0,107,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-behavior: unset;
}

.modal .delete-modal .modal-button svg {
    border-color: white;
}

.modal-fixed .delete-modal .modal-button svg {
    border-color: white;
}

.modalDelete {
    position: absolute;
    z-index: 9;
    top: -32em;
    bottom: 0;
    left: -32em;
    background-color: rgba(13,0,107,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-behavior: unset;
    background-color: rgba(13,0,107,0.8);
    padding-left: 40%;
    padding-top: 10em;
    padding-bottom: 25em;
    padding-right: 70%;
    
}

.new-user-modal {
    left: 50%;
    top: 40%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border: .1em solid rgb(103, 142, 228);
    position: absolute;
    border-radius: .7em;
    padding: 0em;
    scroll-behavior: unset;
    z-index: 9;
    background-color: white;
}

.new-user-modal-open {
    height: 100%;
    width: 100%;
    visibility: visible;
    align-items: center;
    background-color: #f8f8f8;
    -ms-overflow-style: none;  
    scrollbar-width: none;  
    overflow: auto;
}

.add-modal {
    position: fixed;
    z-index: 9;
    bottom: 0;
    top: -4.5em;
    left: 7.1em;
    right:-7.1em;
    background-color: rgba(13,0,107,0.8);
    top: -5em;
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-behavior: unset;
    padding-left: 8em;
    padding-top: 7em;
    padding-bottom: 5em;
}

.delete-modal {
    display: flex;
    flex-direction: column;
    width: 30em;
}

.new-user-modal-open {
    height: 70%;
    width: 60%;
    visibility: visible;
    align-items: center;
    background-color: #f8f8f8;
    -ms-overflow-style: none;  
    scrollbar-width: none;  
    overflow: auto;
}

.project-modal{
    border: .1em solid #5344c1;
    background-color: #ffffff;
    position: absolute;
    width:60%;
    border-radius: .2em;
}

.input{
    border: 2rem;
}
.delete-modal-box{
    background-color: white;
    border-radius: .5em;
    scroll-behavior: unset;
    display: flex;
    flex-direction: column;
  }

.modal-title{
    border-bottom: .1em solid black;
    height: 3em;
    background-color: #f8f8f8;
}

.modal-selectedProject {
    background-color: #f8f8f8;
    border-radius: 0.7rem;
    overflow: hidden;
}

.category-modal-box{
    padding: 0;
}

.modal-open {
    visibility: visible;
}

.modal-open-2 {
    height: 50%;
    width: 45%;
    margin-top: 30%;
    margin-left: 40%;
    visibility: visible;
    align-items: center;
    background-color: rgba(13,0,107,0.8);
}
.modal-closed-2 {
    visibility: hidden;
}

.modal-2-title{
    border-bottom: 0.1em solid #5344c1;
}

.modal-2-content {
    padding: 0em 1em 1em 1em;
}

.modal-closed {
    visibility: hidden !important;
}

.svg-cerrar{
    margin-left: 13rem;
    padding: 0rem 0rem 2rem 0rem;
}

.crear-proyecto-button {
    height: 0.96rem;
    padding: 0.5rem 0.564rem 1.2rem 0.5rem;
    margin-bottom: -1.5em;
    border-radius: 5px;
    border: solid 1px #fff;
    background-color: #5344c1;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.01px;
    text-align: center;
    color: #fff;
    cursor: pointer;
}

.delete-proyecto-button{
    border-radius: .3em;
    border: none;
    background-color: #ee0c0c;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    text-align: center;
    color: #fff;
    cursor: pointer;
}

.purple-button-2{
    padding: 0.4rem;
    margin-right: 1em;
    width: 10em;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    color: white;
    background-color: #5344c1;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.modal-warning{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: small;
    text-align: center;
    margin: 1em 2em;
}

.modal-warning span{
    color: #5344c1;
    font-weight: bold;
}

.modal-button{
    margin-right: -1em;
    margin-top: -1em;
    position: absolute;
    align-self: flex-end;
}

.modal-button-3{
    margin-right: 0px;
    height: 0px;
    margin-left: 98%;
    margin-top: -10rem;
    border:none;
}

.modal-button #Fill-2{
    fill:red;
    transform:scale(1.5);
}

.modal-button-3 #Fill-2{
    fill:red;
    transform:scale(1.5);
}

.modal-button svg{
    width: 30px;
    height: 30px;
}

.modal-button #Fill-1{
    transform:scale(1.5);
}

.modal-button-3 svg{
    width: 30px;
    height: 30px;
}

.modal-button-3 #Fill-1{
    transform:scale(1.5);
}

.left-modal-item{
    border-left: white;
    border-left-style: solid;
    border-left-width: 0.3rem;
    align-items: center;
    width: auto;
    height: 50%;
    padding:1em;
    color: black;
    cursor: pointer;
}

.selected-left-modal-item{
    align-items: center;
    background-color: #d8d3ff;
    border-left: #5344c1;
    border-left-style: solid;
    border-left-width: 0.3rem;
    width: auto;
    height: 50%;
    padding: 1em;
    color: black;
    cursor: pointer;
}

.new-user-modal-title{
    height: 4em;
    background-color: #d8d3ff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .5em;
}

.new-user-modal-title span{
    color:#5344c1;
    font-weight: bold;
    font-size: 1.2em;
}

.new-user-modal-button{
    margin-right: 0px;
    height: 0px;
    padding: 0%;
    margin-left: 97%;
    border:none;
}

.new-user-modal-button #Fill-2{
    fill:red;
    transform:scale(1.5);
}

.new-user-modal-button svg{
    margin-top: -.8rem;
    width: 2rem;
    height: 2rem;
}

.new-user-modal-button #Fill-1{
    transform:scale(1.5);
}

.left-modal-nav{
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 20em;
    height: 7em;
    margin-left: 1rem;
}

.right-modal-nav{
    font-size: large;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: #5344c1;
    border-style:solid;
    border-width:0.1rem;
    margin-left: 10%;
    width: 70%;
    padding: 1em;
    margin-bottom: 2em;
}

.user-modal-content {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2em;
    margin-right: 10%;
    margin-left: 10%;
    align-items: center;
    justify-content: center;
}

.new-user-modal-open::-webkit-scrollbar {
    display: none;
  }

.submit-info-button-modal{
    padding: .35em .10em;
    margin: 5em  3em .5em .5em;
    line-height: .5em;
    border-radius: .5em;
    background-color: #5344c1;
    color: white;
    text-align: center;
    align-content: center;
    align-items:center;
    vertical-align: middle;
    cursor: pointer;
    position: relative;
    font-size: small;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border-color:#5344c1;
    box-shadow: none;
    width: 95%;
}

@media (max-width: 37.5em) {
    .user-modal-content {
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }
    .left-modal-nav {
        flex-direction: row;
        height: auto;
        width: auto;
    }
    .right-modal-content {
        margin: auto;
    }
    .main-content {
        overflow: auto;
    }
}

.black-title {
    margin-left: .4em;
    color: black !important;
}

.modal-avisar {
    padding: 1em 2em;
}

.modal-avisar-2 {
    text-align: center;
    margin: 0.5em 1em;
    padding: 0em 2em;
    background-color: #f8f8f8;
}

.modal-project-logo {
    width: 4em;
    height: 4em;
    margin-left: 3.5em;
    border: 0.1em solid #5344c1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e6952d;
}

.project-left-content-2 {
    height: 8em;
    padding: 0em;
    margin-top: 2em;
    margin-bottom: -4em;
    margin-left: -2em;
    display: flex;
    flex-direction: row;
  }

  .new-user-purple-background {
    position: absolute;
    z-index: 9;
    top: -5em;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(13,0,107,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-behavior: unset;
    padding-left: 8em;
    padding-top: 7em;
    padding-bottom: 5em;
}
  
.modal-edit-project-title{
    margin-top: 4%;
    margin-bottom: -3%;
    height: 5%;
}

.marker-left-content {
    height: 8em;
    padding: 0em;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 60%;
    margin-left: 3em;
  }
  
.marker-right-content {
    height: 8em;
    padding: 0em;
    display: flex;
    margin-right: 0%;
    margin-left: 17em;
    margin-top: -8.5em;
    flex-direction: row;
    align-items: center;
}

span.grey {
    background: pink;
    border-radius: 0.8em;
    -moz-border-radius: 0.8em;
    -webkit-border-radius: 0.8em;
    color: #fff;
    display: inline-block;
    font-weight: bold;
    line-height: 1.6em;
    margin-right: 15px;
    text-align: center;
    width: 1.6em; 
  }
.header-categories-options-button .button {
    font-size: 2.5em;
    border-color: transparent;
    background-color: transparent;
    position: relative;
    border-width: 0;
}

.vertical-button {
    writing-mode: vertical-lr;
}

.header-categories-options-button{
    border-color: transparent;
    background-color: transparent;
    position: relative;
    border-width: 0;
}

.header-categories-options {
    writing-mode: horizontal-tb;
    position: absolute;
    background-color: #d8d3ff;
    height: auto;
    z-index: 1;
    right: 50%;
    top: 80%;
    width: 650%;
}

.header-categories-options.hidden {
    visibility: hidden;
}


.header-categories-options.active {
    visibility: visible;
}

.header-categories-options .button {
    font-size: 1em;
    font-weight: 400;
    color: black;

}

.meeting-options-and-button {
    display: flex;
    position: relative;
}

.new-meeting-type{
    background-color: #d8d3ff;
    border-radius: 1em;
    margin-top: 0rem;
    margin-bottom: 0rem;
}

.new-meeting-type input[type="text"]{
    background-color: white;
}

.new-meeting-type .project-modal{
    padding: 0em 0em;
    background-color: #d8d3ff;
}