.meeting-note-modal{
    display: flex;
    scroll-behavior: unset;
    z-index: 9;
    background-color: white;
    border-radius: 10px;
}

.meeting-note-list-content {
    padding: 1rem 1rem;
    padding-right: 1.5rem;
    height: 20rem;
    overflow-y: scroll;
    flex-grow: 1;
    background-color: #ffffff;
    border-radius: 10px;
}

.meeting-note-list-content::-webkit-scrollbar {
    display: none;
  }

.add-note-button-box {
    width: 100%;
    margin-top: -1.3rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
}

.add-note-button {
    height: 0.96rem;
    padding: 0.5rem 0.564rem 1.2rem 0.5rem;
    border-radius: 5px;
    border: solid 1px #fff;
    background-color: #5344c1;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.01px;
    text-align: center;
    color: #fff;
    cursor: pointer;
}