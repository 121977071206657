.instance-container {
  display: flex;
  flex-direction: column;
}

.instance-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.instance-container::before {
  left: 0;
  bottom: 0;
  width: 50%;
  border-bottom: 1em solid #5344c1;
}
/* .instance-container::before {
  position: absolute;
  content: "";
  left: 22em;
  bottom: 18.5em;
  width: 18em;
  border-bottom: 0.1em solid #5344c1;
} */

.instance-left-content {
  display: flex;
  flex-direction: row;
  margin-left: 1.5em;
}

.instance-right-content {
  display: flex;
  flex-direction: row;
  margin-right: 1.5em;
}

.svg-container {
  font-size: 1em !important;
  padding-top: 0.5em !important;
  background-color: transparent;
  border: 0;
}

.circle {
  border-radius: 50%;
  background-color: black;
  height: 1em;
  width: 1em;
  margin-right: 0.5em;
  margin-top: 0.2em;
}

.color-#CD5C0C {
  color: #cd5c0c !important;
}

.instance-divider {
  margin: 0;
  margin-bottom: -0.1em;
  width: 89%;
  border-bottom: 0.1em solid #5344c1;
  border-top: 0.1em #5344c1;
  align-self: center;
}

.instance-divider-green {
  margin: 0;
  margin-bottom: -0.1em;
  width: 89%;
  border-bottom: 0.1em solid #67b017;
  border-top: 0.1em #67b017;
  align-self: center;
}

.circle.filter-yellow {
  background-color: #f5a623;
}
.filter-yellow {
  color: #f5a623;
}


.circle.filter-purple {
  background-color: #bd10e0;
}
.filter-purple {
  color: #bd10e0;
}

.circle.filter-lightblue {
  background-color: #4a90e2;
}
.filter-lightblue {
  color: #4a90e2;
}


.circle.filter-red {
  background-color: #ff0000;
}
.filter-red {
  color: #ff0000;
}

.filter-lightgreen {
  color: #46c7aa;
}
.circle.filter-lightgreen {
  background-color: #46c7aa;
}

.circle.filter-orange {
  background-color: #e36b00;
}
.filter-orange {
  color: #e36b00;
}


.circle.filter-grey {
  background-color: grey;
}
.filter-grey {
  color: grey;
}

.filter-pink {
  color: #ff0fa9;
}
.circle.filter-pink {
  background-color: #ff0fa9;
}

.filter-darkgreen {
  color: #00655a;
}
.circle.filter-darkgreen {
  background-color: #00655a;
}

.filter-darksalmon {
  color: #e9967a;
}
.circle.filter-darksalmon {
  background-color: #e9967a;
}

.filter-lightslategrey {
  color: #789;
}
.circle.filter-lightslategrey {
  background-color: #789;
}
