.settings-buttons-list {
  display: flex;
  flex-direction: row;
  border-color: white;
  border-width: 0em;
  border-left-width: .1em;
  border-style: solid;
  padding-left: 1em;
  margin-left: 1em;
}

.settings-buttons-list .button {
  margin-right: .7em;
  border-radius: .2em;
  background-color: white;
  opacity: 1;
}

.settings-buttons-list .button:active {
  color: #d8d3ffd3;
  opacity: .75;
}

.settings-buttons-list .button.inactive {
  opacity: .5;
}