.modal-content {
    padding: 0em 0em;
}

.modal-open {
    visibility: visible;
}

.modal-closed {
    visibility: hidden !important;
}

.closing-x #Fill-2{
    display: flex;
    z-index: 10;
    fill:#ee0c0c;
    justify-self: center;
    align-self: center;
    }

.closing-x svg{
    color: white;
    margin-left: -0.07035em;
    margin-top: 0.25em;
}

.project-modal-box {
    scroll-behavior: unset;
    z-index: 9;
    background-color: #d8d3ff;
    border-radius: .5em;
}

.project-modal-box h2{
    color: #5344c1;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: x-large;
    font-weight: bold;
    text-align: center;
}

.new-project-top{
    display: flex;
    position: relative;
    flex-direction: row;
    background-color: #d8d3ff;
    padding-bottom: 2em;
    height: 6em;
    margin-top: -1em;
    margin-bottom: .5em;
}

.new-project-bottom{
    display:flex;
    position: relative;
    background-color: #ffffff;
    flex-direction: column;
    padding: .5em;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.new-project-left-content {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
}

.new-project-right-content {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0em;
    display: flex;
    flex-direction: column;
}

.name-project-field {
    width: 90%;
    height: 1.2rem;
    margin-top: .2em;
    margin-left: 3%;
    border-radius: 0px;
    border: none;
    background-color: #f0eeff;
    text-align: start;
    word-wrap: break-word;
}

.description-project-field {
    width: 90%;
    height: 3.55rem;
    margin-left: 3%;
    border-radius: 0px;
    border: none;
    background-color: #f0eeff;
    text-align: start;
    word-wrap: break-word;
}

.new-project-logo {
    width: 6.2em;
    height: 6.2em;
    margin-left: 2.5em;
    margin-top: 16%;
    border: 0.1em solid #5344c1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e6952d;
    background-color: white;
}

.list-container{    
    margin-left: 0rem;
    height: 95%;
    width: 100%;
    min-height: 15em;
    overflow-y: scroll;
    flex-grow: 1;
    background-color: #ffffff;
}

.list-container::-webkit-scrollbar {
    display: none;
}

.list-container-new-project{
    padding: .3rem .3rem;
    padding-left: 0em;
    padding-top: 0em;
    margin-top: 0em;
    margin-left: 0em;
    min-height: 10em;
    height: 15em;
    overflow-y: scroll;
    flex-grow: 1;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    scroll-behavior: unset;
    overflow: hidden;
}

.list-container-new-project::-webkit-scrollbar {
    display: none;
}

.new-project-list-content{
    margin-left: 0rem;
    height: 100%;
    width: 100%;
    min-height: 15em;
    overflow-y: scroll;
    flex-grow: 1;
    background-color: #ffffff;
}

.new-project-list-content::-webkit-scrollbar {
    display: none;
  }

.new-project-button {
    display: flex;
    margin-top: 1em;
    height: 0.96rem;
    padding: 0.5rem 0.564rem 1.2rem 0.5rem;
    border-radius: 5px;
    border: none;
    background-color: #5344c1;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.01px;
    text-align: center;
    align-self: center;
    color: #fff;
    cursor: pointer;
}