  .Top-container {
    width: auto;
    height: 5vh;
    padding: 1vh 0 1rem 1rem;
    background-color: rgba(245, 166, 35, 0.1);
    border: solid 0.1em #5344c1;
  }

  .Top-container.yellow {
    background-color: rgba(245, 166, 35, 0.1);
  }
  .Top-container.purple  {
    background-color:#bd10e025 ;
}

.Top-container.lightblue  {
    background-color:#4a91e21f ;
}

.Top-container.red  {
    background-color:rgba(255, 0, 0, 0.164) ;
}

.Top-container.lightgreen  {
    background-color:#46c7ab1e ;
}

.Top-container.orange  {
    background-color:#e36a0021 ;
}

.Top-container.pink  {
    background-color:#ff0fa72f ;
}

.Top-container.darkgreen  {
    background-color:#0065592a ;
}

.Top-container.darksalmon  {
  background-color: rgba(233, 150, 122, 0.1) ;
}

.Top-container.lightslategrey  {
  background-color: rgba(119, 136, 153, 0.1) ;
}
  

  

  .Note-figure {
    margin: 1vh 1vh 0 0;
    float:left;
    position: inline-flex;
  }

  .Note-figure.yellow polygon{
    fill:#f5a623;
  }


  .Note-figure.purple polygon  {
      fill:#bd10e0 ;
  }

  .Note-figure.lightblue polygon  {
      fill:#4a90e2 ;
  }

  .Note-figure.red polygon  {
      fill:#f00 ;
  }

  .Note-figure.lightgreen polygon  {
      fill:#46c7aa ;
  }

  .Note-figure.orange polygon  {
      fill:#e36b00 ;
  }

  .Note-figure.pink polygon  {
      fill:#ff0fa9 ;
  }

  .Note-figure.darkgreen polygon  {
      fill:#00655a ;
  }

  .Note-figure.darksalmon polygon  {
    fill: darksalmon ;
  }

  .Note-figure.lightslategrey polygon  {
    fill: lightslategrey ;
  }


  .Note-group {
    font-family: Roboto;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.01px;
    color: #f5a623;
  }

  .Note-group.yellow{
    color: #f5a623;
  }

  .Note-title {
    font-family: Roboto;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.02px;
    color: #4a4a4a;
  }



  .Date {
    float: right;
    margin-right: 1rem;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.01px;
    color: #5344c1;
    
  }

  .Date-tag {
    float: right;
    margin-right: 1rem;
    font-family: Roboto;
    font-size: 10px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.01px;
    color: #4a4a4a;
    margin-top:0.5rem;
  }

  .Sub-menu{
    width:6em;
    height: 100%;
    background-color: #fff;
    border-right: solid 2px #5344c1;
    display:flex;
    flex-direction: column;
  }

  .Rectangle-Selected {
    width:auto;
    height: 6em;
    border-bottom: solid 2px #5344c1;
    background-color: #f5a623;
    display: flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
  }

  .Rectangle-Selected g{
    fill:#fff;
  }

  .Rectangle-Selected text{
    font-family: Roboto;
    font-size: 13.8px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.55px;
    text-align: center;
    fill: #725eb7;
  }

  .Rectangle-Stroke-Unselected text{
    font-family: Roboto;
    font-size: 13.8px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.55px;
    text-align: center;
    fill: #fff;
    stroke:#fff;
    stroke-width: 0.005;
  }



  .Rectangle-Stroke-Unselected {
    width:auto;
    height: 6em;
    border-bottom: solid 2px #5344c1;
    background-color: #fff;
    display: flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
  }

  .Rectangle-Stroke-Unselected g{
    fill:#5344c1;
    stroke: #5344c1;
  }

  .Rectangle-Unselected {
    width:auto;
    height: 6em;
    border-bottom: solid 2px #5344c1;
    background-color: #fff;
    display: flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
  }

  .Rectangle-Unselected g{
    fill:#5344c1;
  }

  .Rectangle-Unselected text{
    font-family: Roboto;
    font-size: 13.8px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.55px;
    text-align: center;
    fill: #fff;
  }

  .Rectangle-Arrow {
    width:auto;
    height: 4rem;
    border-top: solid 2px #5344c1;
    background-color: #f5a623;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    margin-top: auto;
  }
  .Rectangle-Arrow g{
    fill:#fff;
  }

  .Rectangle-Arrow svg{
    transform: rotate(180deg);
  }

  .Note-info{
    height: 2rem;
  }


