/* *Pendiente solucionar interferencias con SidePanel.css y Header.css */

button{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border-style: solid;
    cursor: pointer;
  }
  
#closing-x{
  background-color: red; 
  width: 2em;
  height: 2em;
  color: white;
  font-size: 1em;
  font-weight: bolder;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  border-radius: 50%;
  border-color: #d8d3ff;
  border-style: solid;
  border-width: .2em;
  z-index: 9;
  position: absolute;
  margin-right: -1em;
  margin-top: -1em;
  align-self: flex-end;
}

#agendar {
  display: flex;
  background-color: #5344c1;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
  border-radius: .8em;
  padding: .5em .5em;
  margin: 2em 2em;
  align-self: center;
  left: 50%;
  width: fit-content;
  padding: .5em .5em;
}

.meeting-form {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0em 0em 0.5em 0.5em;
}

#add-meeting-type {
  display: flex;
  background-color: #5344c1;
  border-color: #5344c1;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
  border-radius: .3em;
  margin: .5em .5em;
  text-align: center;
  font-size: x-large;
  padding: 0em .2em;
  border-width: .1em;
}

.name-field{
  display: flex;
  position: relative;
  padding: .65em .65em;
  margin: 0em 1em;
  width:20em;
  border: none;
  background-color: #d8d3ff;
  color: #5344c1;
}

.date-field{
  display: flex;
  position: relative;
  padding: .5em .5em;
  margin: 0em .5em;
  margin-right: 35em;
  border: none;
  background-color: #d8d3ff;
  color: #5344c1;
}

.modal-overlay {
  position: absolute;
  z-index: 1;
  top: -4.5em; /* Interfiere con la barra superior */
  right: -7.1em; /* Interfiere con la barra menú lateral */
  bottom: 0;
  left: 0;
  background-color: rgba(13,0,107,0.8); /* Fondo morado transparente */
  }

.new-meeting-modal {
  display: flex;
  position: relative;
  padding-top: 1.5em;
  padding-right: 1.5em;
  height: 62.55rem;
  width: 62.45rem;
  border-radius: 0;
  scroll-behavior: unset;
  align-self: center;
  justify-self: center;
}

.new-meeting-modal-box{
  display: flex;
  flex-direction: column;
  min-height: 80%;
  min-width: 5%;
  max-width: 70%;
  max-height: 80%;
  padding-left: 0em;
  flex: 1;
  background-color: white;
  object-fit: contain;
  align-content: center;
  justify-content: center;
}

.new-meeting-details {
  margin-left: 3em;
  display: flex;
  align-items: center;
}

.new-meeting-details svg{
  filter: invert(26%) sepia(59%) saturate(2445%) hue-rotate(234deg) brightness(87%) contrast(91%);
}

.widget-container {
  padding: 1em;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1em;
}

.project-logo {
  float: left;
  margin: 1em 1em;
}

.project-data {
  margin: 1em 1em;
  vertical-align: middle;
}

.redico-header {
  width: auto;
  height: 4%;
  border-bottom: 0.1em solid black;
  display: flex;
  flex-direction: row;
  padding-top: 0.5em;
}

.redico-header h2 {
  margin: 0;
  margin-left: 1em;
}

.redico-header-right-buttons {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.fill-screen {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}

.content-content {
  width: 100%;
  height: 100%;
}

.widget-container {
  flex-direction: row;
  margin: 0;
  left: 50%;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  overflow: hidden;
}

.formMeetings{
  background-color: #fff;
  border-radius: 0em 0em .5em .5em;
}

.list-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;  
  width: 100%;
  height: 92%;
  margin: .3em .3em;
}

.modal-title{
    border-bottom: 0;
}

/* .modal-content {
  margin-top: 0em;
  margin-left: 0em;
  margin-bottom: 0em;
  margin-right: 0em;
} */
  
.modal-open {
  visibility: visible;
}
  
.modal-closed {
  visibility: hidden;
}

.project-header-2{
  /* border-bottom-width: 0; */
  width: 100%;
  background-color: #d8d3ff;
  height: 8em;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: .5em .5em 0em 0em;
}

.project-header-2 .project-logo {
  width: 4em;
  height: 4em;
  border: 0.1em solid #5344c1;
}

.project-header-2 .project-data {
  margin-left: 1.5em;
  color: #4a4a4a;
}

.project-header-2 .button {
  writing-mode: vertical-rl;
  border-width: 0em;
  background-color: transparent;
  color: #5344c1;
  font-weight: bold;
  font-size: 2em;
  padding-top: 2em;
  height: auto;
}

.project-page .project-header-3{
  /* border-bottom-width: 0; */
  background-color: #d8d3ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 8em;
}

.project-header-3 .project-logo {
  width: 4em;
  height: 4em;
  border: 0.1em solid #5344c1;
}

.project-header-3 .project-data {
  margin-left: 1.5em;
  color: #4a4a4a;
}

.project-header-3 .button {
  writing-mode: vertical-rl;
  border-width: 0em;
  background-color: transparent;
  color: #5344c1;
  font-weight: bold;
  font-size: 2em;
  padding-top: 2em;
  height: auto;
}

.less-right {
  right: -8em !important;
}