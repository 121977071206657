.loading-icon {
  border-radius: 50%;
  width: 5em;
  height: 5em;
  border-width: 1em;
  border-style: solid;
  border-color: #d8d3ff;
  border-bottom-color: #5344c1;

  margin: auto;
  -webkit-animation: rotation 2s infinite cubic-bezier(0.46, 0.11, 0.85, 0.57);
  -moz-animation: rotation 2s infinite cubic-bezier(0.46, 0.11, 0.85, 0.57);
  -o-animation: rotation 2s infinite cubic-bezier(0.46, 0.11, 0.85, 0.57);
  animation: rotation 2s infinite cubic-bezier(0.46, 0.11, 0.85, 0.57);
}

@-webkit-keyframes rotation {
  from {-webkit-transform: rotate(0deg);}
  to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
  from {-moz-transform: rotate(0deg);}
  to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
  from {-o-transform: rotate(0deg);}
  to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
  from {transform: rotate(0deg);}
  to {transform: rotate(359deg);}
}