/* *Pendiente solucionar interferencias con SidePanel.css y Header.css */

button{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  border-style: solid;
  cursor: pointer;
}

#closing-x-category{
  position: absolute;
  top: -1em;
  right: -1em;
  background-color: red; 
  width: 1.7em;
  height: 1.7em;
  color: white;
  text-align: center;
  font-size: 1.7em;
  font-weight: bolder;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  border-radius: 50%;
  border-color: #d8d3ff;
  border-style: solid;
  border-width: .2em;
  z-index: 9;
}

#agendar {
  display: flex;
  background-color: #5344c1;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
  border-radius: .8em;
  padding: .5em .5em;
  margin: 2em 2em;
  align-self: center;
  left: 50%;
  width: fit-content;
  padding: .5em .5em;
}

#add-meeting-type {
  display: flex;
  background-color: #5344c1;
  border-color: #5344c1;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
  border-radius: .3em;
  margin: .5em .5em;
  text-align: center;
  font-size: .8em;
  padding: 0.3em .5em;
  border-width: .1em;
}


.date-field{
  display: flex;
  position: relative;
  padding: .5em .5em;
  margin: .5em .5em;
  border: none;
  background-color: #d8d3ff;
  color: #5344c1;
}

.modal-element {
  display: flex;
  flex-shrink: 10;
  flex-grow: 10;
  flex-basis: 0;
  position: absolute;
  align-self: center;
  justify-self: center;
  margin-top:4em;
}

.modal-category-element {
  display: flex;
  flex-shrink: 10;
  flex-grow: 10;
  flex-basis: 0;
  position: absolute;
  align-self: center;
  justify-self: center;
}


.new-meeting-modal {
  display: flex;
  position: relative;
  border-radius: 0;
  scroll-behavior: unset;
  align-self: center;
  justify-self: center;
}

.new-meeting-category-modal{
  display: flex;
  /* position: relative;
  border-radius: 0;
  scroll-behavior: unset;
  align-self: center;
  justify-self: center; */
}

.new-category-meeting-modal-box{
  display: flex;
  flex-direction: column;
  align-items: center;
  /* flex: 1;
  background-color: white;
  object-fit: contain;
  align-content: center;
  justify-content: center;
  border-radius: 8px; */
}

.meeting-details {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: row;
  float: left;
  margin: 0em 1.5em;
}

.meeting-details svg{
  filter: invert(26%) sepia(59%) saturate(2445%) hue-rotate(234deg) brightness(87%) contrast(91%);
}

.widget-container {
  padding: 1em;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-between;
  width:100%
}

.project-header-category{
  margin: 0em 0em;
  padding: 2em 1em;
  background-color: #d8d3ff;
}

.list-content-category::-webkit-scrollbar {
  display: none;
}

.project-logo {
  float: left;
  margin: 1em 1em;
}

.project-data {
  margin: 1em 1em;
  vertical-align: middle;
}

.redico-header {
  width: auto;
  height: 4%;
  border-bottom: 0.1em solid black;
  display: flex;
  flex-direction: row;
  padding-top: 0.5em;
}

.redico-header h2 {
  margin: 0;
  margin-left: 1em;
}

.redico-header-right-buttons {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.fill-screen {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}

.content-content {
  width: 100%;
  height: 100%;
}

.widget-container {
  flex-direction: row;
  margin: 0;
  left: 50%;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  overflow: hidden;
}

.list-container-category {
  display: flex;
  flex-direction: column;
  flex-grow: 1;  
  width: 100%;
  height: 100%;
  margin: .3em .3em;
}

.modal-title{
    border-bottom: 0;
}

/* .modal-content {
  margin-top: 0em;
  margin-left: 0em;
  margin-bottom: 0em;
  margin-right: 0em;
} */

.modal-open {
    visibility: visible;
}

.modal-closed {
    visibility: hidden;
}
/* 
#ELEMENTOS #Group-6 {
  fill:#5344c1;
} */

.left-line #ELEMENTOS #Group-6 {
  fill:none;
}