.new-marker-modal{
    display: flex;
    position: relative;
    border-radius: 0;
    scroll-behavior: unset;
    align-self: center;
    justify-self: center;
    width:90%;
    height:90%;
  }

  .new-marker-modal-box{
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: white;
    object-fit: contain;
    align-content: center;
    justify-content: center;
  }

  .marker-modal {
    height: 40%;
    width: 50%;
    max-width: 25rem;
    margin-top:5%;
    margin-left: -5%;
    position: absolute;
    padding: 1em;
    padding: 0em;
    scroll-behavior: unset;
    z-index: 9;
}

.modal-marker-title{
    margin-top: -0%;
    color: #c14459;
    height: 5em;
    background-color: #d8d3ff;
}

.modal-marker-aviso{
    text-align: center;
    align-content: center;
    margin-top: 2em;
    background-color: #d8d3ff;
    padding-top: 10%;
}

.modal-marker-avisar-2 {
    text-align: center;
    margin-top: 1em;
    margin-bottom: 3em;
    padding: 3% 5% 5% 3%;
    color: gray;
    background-color: #f8f8f8;
    font-family: Roboto;
    font-size: 0.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.01px;
    color: #4a4a4a;
}

.name-marker-field{
    height: 15%;
    margin-left: 0.5rem;
    display: flex;
    position: relative;
    border: none;
    margin-top: -2.5em;
    background-color: transparent;
    font-size: 1rem;
    color: #5344c1;
    font-weight: bolder;
    width: 15rem;
    font-family: Roboto;
    font-size: 1.15rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    color: #5344c1;
  }

  .name-marker-field:focus-visible{
    outline: none;
  }
  .name-marker-field::placeholder{
    color: #5344c16c;
    font-weight: bolder;
    font-family: Roboto;
    font-size: 1.15rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    color: #5344c1;
  }

  .name-marker-field input{
    height: 40%;
    display: flex;
    position: relative;
    padding: 10% 8%;
    border: none;
    background-color: transparent;
    color: #5344c1;
  }

.crear-marker-button {
    height: 0.96rem;
    width: 38%;
    padding: 0.5rem 0.564rem 1.2rem 0.5rem;
    margin-bottom: 1.5em;
    margin-left: 64%;
    margin-right: 31%;
    border-radius: 5px;
    border: solid 1px #fff;
    background-color: #5344c1;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.01px;
    text-align: center;
    color: #fff;
    cursor: pointer;
}

.dropdown-marker{
  border-radius: 0.5rem;
  color: #4a4a4a;
  border-color: #5344c1;
  padding: 0% 0% 0% 3%;
}

.dropdown-marker:focus-visible{
  outline: none;
}

.colors-wrapper{
  margin-left: 4.2rem;
  border: 1px solid rgb(25, 0, 255);
  border-radius: 0.3rem;
  padding: 0.3rem;
  background-color: white;
  position: absolute;
  top: 1.5rem;
}
.header-new-marker-modal{
  display: flex;
  gap: 0.5rem;
}
.color-initial{
  width: 0.8rem;
  height: 0.8rem;
  background-color: rgba(209, 200, 200, 0.993);
  border-radius: 50%;
  margin-top: auto;
  margin-bottom: auto;
}
.flecha-abajo{
  margin-top: -0.2rem;
}

.body-new-marker-modal{
  /* display: flex;
  flex-direction: column; */
  display: none;
  margin-top: 0.6em;
}

.body-new-marker-modal.active{
  display: block;
}

.iconMarkerModal{
  margin-top: -2.5rem;
  margin-left: -1.5rem;
}

.color-option-new-marker-modal{
  width: 0.8rem;
  height: 0.8rem;
  background-color: rgba(209, 200, 200, 0.993);
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.6rem;
}

.colors-wrapper .yellow  {
  background-color: #f5a623;
}
.colors-wrapper .purple  {
  background-color: #bd10e0;
}
.colors-wrapper .lightblue  {
  background-color: #4a90e2;
}
.colors-wrapper .red  {
  background-color: #f00;
}
.colors-wrapper .lightgreen  {
  background-color:#46c7aa;
}
.colors-wrapper .orange  {
  background-color: #e36b00;
}
.colors-wrapper .pink  {
  background-color: #ff0fa9;
}
.colors-wrapper .darkgreen  {
  background-color: #00655a;
}
.colors-wrapper .darksalmon  {
  background-color: darksalmon;
}
.colors-wrapper .lightslategrey  {
  background-color: lightslategrey;
}

.select_box{
  width: 80%;
  margin-left: 1%;
  overflow: hidden;
  border: 1px solid #5344c1;
  border-radius: 0.5rem;
  position: relative;
  padding: 10px 0;
}

.select_box:after{
  width: 0; 
  height: 0; 
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #5344c1;
  position: absolute;
  top: 40%;
  right: 5px;
  content: "";
  z-index: 98;
 }

.select_box select{
  width: 100%; 
  border: 0;
  position: relative;
  z-index: 99;
  background: none;
	appearance: none;
}

.selectGroup{
  display: flex;
  align-items: center;
  margin-top: 2em;  
  gap: 1rem;
}

.buttonMarker{
  border-radius: 0.5rem;
  border-color: #5344c1;
  padding: 1% 3% 1% 3%;
  background-color: rgb(201, 201, 235);
}

.notice-div-marker {
  margin-top: -4.3rem;
  align-items: center;
  margin-left: 15%;
  margin-right: 25%;
  width: 100%;
  height: 0.3rem;
  font-size: 0.8rem;
  color: rgb(170, 0, 0);
}