.redico-content {
  padding: 0em;
  height: 100%;
  display: flex;
  flex-direction: row;
  transform: translateX(-7.1em);
  transition: all 0.1s;
}

.redico-content.hidden {
  transform: translateX(0em);
}

.main-content { 
  padding: 1em 3em;
  /* flex-grow: 1; */
  width: 93%;
  height: 92%;
  overflow-y: scroll;
  margin-right: 0em;
  display: flex;
  flex-direction: column;
}
.main-content::-webkit-scrollbar {
  display: none;
}

.current-content {
  margin-right: -7.1em;
  /* flex-grow: 1; */
  width: 100%;
  height: 92%;
  display: flex;
  flex-direction: row;
}

.main-content-hidden {
  margin-right: -7em;
}

.main-active {
  transition: 0.5s;
  max-width: 92%;
  min-width: 80%;
  padding: 1em 0em 1em 2em;
  margin-right: 10rem;
}

.main-content-2 {
  padding: 1em 3em;
  flex-grow: 1;
  overflow-y: hidden;
  margin-right: 0em;
  display: flex;
  flex-direction: column;
}

.main-active-2 {
  transition: 0.5s;
  max-width: 92%;
  min-width: 80%;
  padding: 1em 0.2em;
}

@media only screen and (max-width: 34em) {
  .main-content {
    padding: .5em 0em;
    padding-bottom: 0em;
    width: 100%;
  }

  .redico-content {
    height: 100vh;
    margin-top: -3.5em;
  }
}
