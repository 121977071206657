.signin-content body{
  margin: 0 0;
  height: 100%;
  color: #fff;
  background-color: #5344c1;
  background-image: url("/src/routes/svg/LOGIN-IMG.svg");
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.signup-content body{
  margin: 0 0;
  height: 100%;
  color: #fff;
  background-color: #5344c1;
  background-image: url("/src/routes/svg/LOGIN-IMG.svg");
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.signin-content h2{
  font-family: Roboto, sans-serif;
  text-align: center;
}

.signup-content h2{
  font-family: Roboto, sans-serif;
  text-align: center;
}

/* .signin-content h3{
  font-family: Roboto, sans-serif;
  text-align: center;
  margin-top: 2rem;
}

.signup-content h3{
  font-family: Roboto, sans-serif;
  text-align: center;
  margin-top: 2rem; 
}*/

.signup-content {
  width: 16rem;
  height: 34rem;
  padding: 1.5rem 1.425rem 0.842rem;
  border-radius: 8px;
  background-color: #7165cb;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #fff;
  margin-top: 2rem;
}

.signin-content {
  width: 16rem;
  height: 20rem;
  padding: 1.08rem 1.425rem 0.842rem;
  border-radius: 8px;
  background-color: #7165cb;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #fff;
}

.notice-div {
  margin-top: 0.5rem;
  width: 100%;
  height: 1.32rem;
  text-align: center;
  color: rgb(170, 0, 0);
}

.mid-grid {
  grid-row: 2;
  grid-column: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  margin-top: 2rem;
}

.Login-Usuario {
  display: block;
  width: 100%;
  height: 1.22rem;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.02px;
  text-align: center;
  color: #fff;
}

.signup-Usuario {
  font-size: 1.2rem !important;
}

.signup-types {
  display: block;
  margin-top: 1.5rem;
  width: 100%;
  height: 1.22rem;
  font-family: Roboto, sans-serif;
  font-size: 0.9rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.02px;
  text-align: left;
  color: #fff;
}

.logo-div {
  width: 39.791667vw;
  height: 6.555556vh;
  margin: 10.111112vh 0 0;
  padding: 0 0 0 8.958334vw;
}

.black-filter {
  filter: #000000;
}

.login-field {
  width: 100%;
  height: 1.082rem;
  padding: .2rem .2rem;
  margin-bottom: 1rem;
  border-radius: 6px;
  border: solid 0px #fff;
  background-color: #fff;
  font-family: Roboto, sans-serif;
  font-size: 0.75rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #4a4a4a;
}

.button-div {
  width: 100%;
  margin-top: .5rem;
  display: flex;
  justify-content: center;
}

.button-div-2 {
  padding: 1em;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button-div-3 {
  width: 100%;
  height: 3em;
  display: flex;
  justify-content: center;
}

.button-div-4 {
  padding: 1em;
  display: flex;
  justify-content: center;
}

.login-button {
  width: 7.12rem;
  height: 0.96rem;
  padding: 0.5rem 0.564rem 1.2rem 0.5rem;
  border-radius: 5px;
  border: solid 1px #fff;
  background-color: #5344c1;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
}

.grid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 1fr 3fr 1fr; */
  /* grid-gap: 10px; */
  background-color: #5344c1;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-image: url("/src/routes/svg/LOGIN-IMG.svg");
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
}

.first-mid-row {
  /* grid-row: 1;
  grid-column: 2; */
  margin-top: 2em;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.third-third-row {
  /* grid-row: 3;
  grid-column: 3; */
  display: flex;
  margin-top: 2%;
  justify-content: flex-end;
}

.redico-logo {
  width: 3.5rem;
  height: 3.5rem;
  /* margin-top: 10%; */
  /* margin-left: 67%; */
  margin: 0% 10% 5% 0%;
  border-radius: 10px;
  background-color: black;
}

.login-logo {
  margin-top: 3rem;
  width: 42.5rem;
  height: 4rem;
  filter: invert(100%) sepia(5%) saturate(19%) hue-rotate(121deg)
    brightness(106%) contrast(105%);
}

.forgot-buttons {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.forgot-password-button {
  width: 50%;
  opacity: 0.8;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: 0.01px;
  text-align: center;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}

.verify-message {
  color: white;
  font-family: Roboto, sans-serif;
  text-align: center;
}

.descriptionProject-field {
  width: 95%;
  margin-left:2.5%;
  padding: 0em;
  height: 3.52rem;
  border-radius: 5px;
  border: solid 1px #5344c1;
  background-color: #d8d3ff;
  font-family: Roboto, sans-serif;
  font-size: 0.75rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  text-align: start;
  color: #4a4a4a;
  max-width: 100%;
  word-wrap: break-word;
}

.nameProject-fieldEdit {
  margin-top: 2em;
  margin-left: 3%;
  margin-right: 1.5em;
  width: 96vh;
  height: 1.22rem;
  border-radius: 5px;
  border: solid 1px #5344c1;
  background-color: #d8d3ff;
  font-family: Roboto, sans-serif;
  font-size: 0.75rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  text-align: start;
  color: #4a4a4a;
  word-wrap: break-word;
}

.nameProject-field {
  margin-top: 2em;
  margin-left: 3%;
  width: 625%;
  height: 1.22rem;
  border-radius: 5px;
  border: solid 1px #5344c1;
  background-color: #d8d3ff;
  font-family: Roboto, sans-serif;
  font-size: 0.75rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  text-align: start;
  color: #4a4a4a;
  word-wrap: break-word;
}

.nameCategory-field {
  width: 94.5%;
  margin-left:2.5%;
  height: 1.22rem;
  border-radius: 5px;
  border: solid 1px #5344c1;
  background-color: #d8d3ff;
  font-family: Roboto, sans-serif;
  font-size: 0.75rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  text-align: start;
  color: #4a4a4a;
  word-wrap: break-word;
}

/* .form-container{
  display: flex;
  flex-direction: row;
}
*/

.user-info{
  margin-top: 1rem;
  /* display: flex;
  flex-direction: column;
  width: 50%;
  float: left;
  padding-right: 1rem; */
}


/*.org-info{
   display: flex;
  flex-direction: column;
  width: 50%;
  float: left;
  padding-left: 2rem;
  border-width: 5px;
  border-left-color: #ffffff; 
} */

.field-icon {
  float: right;
  margin-right: .2rem;
  margin-top: -2.2rem;
  position: relative;
  z-index: 2;
  color: #5344c1
}

.fa-fw {
  width: 1.28571429em;
  text-align: center;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* :after, :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
} */
