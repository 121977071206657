.annotation-add-modal {
    position: fixed;
    right: -7.1em;
    left: 7em;
    top: -4.5em;
    bottom: 4.5em;
    z-index: 9;
    background-color: rgba(13,0,107,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-behavior: unset;
}

.meeting-details{ 
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: 0% !important;
    margin-top: 2%;
    margin-bottom: 2%;
    overflow: hidden;
}

.meeting-details-child-logos {
    width: min-content;
    height: min-content; 
    display: flex;
    align-items: center;
    margin-right: 1%;
    margin-left: 1%;
}

.meeting-details-smaller-logo {
    height: 60%;
}

.meeting-details-child-1 {
    width: max-content;
    height: 100%;
    font-size: 0.8em;  
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.01px;
    text-align: right;
    color: #4a4a4a;
    display: flex;
    align-items: center;
}

.meeting-details-child-2 {
    width: max-content;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.01px;
    height: 100%;
    font-size: 0.8em;  
    color: #4a4a4a;
    text-align: right;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.meeting-details-child-3 {
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.01px;
    width: max-content;
    height: 100%;
    font-size: 0.8em;  
    color: #4a4a4a;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.meeting-details-line {
    width: min-content;
    border-left: 1.8px solid #5344c1;
    height: 80%;
    display: flex;
    align-items: center;
    margin-right: 1%;
}

.green-line{
    border-left: 1.8px solid #67b017;
}
.green-svg-color {
    filter: invert(60%) sepia(69%) saturate(533%) hue-rotate(46deg) brightness(87%) contrast(90%) !important;
}

.green-color-annotation {
    color: #67b017 !important;
}

.meeting-annotation-info{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: #67b017;
    width: -webkit-fill-available;
    border-radius: 0em 0em 0.5em 0.5em;
}

.meeting-annotation-info-flex{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 98%;
}

.meeting-annotation-user-audio {
    margin-top: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.white-color-svg {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(233deg) brightness(102%) contrast(106%);
}

.meeting-annotation-user-logo {
    width: min-content;
    height: min-content; 
    display: flex;
    align-items: center;
    margin-right: 2%;
}

.meeting-annotation-user-data {
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.01px;
    width: max-content;
    height: 100%;
    font-size: 0.8em;  
    color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-right: 15%;
}

.meeting-user-org{
    font-size: 0.7em;
    width: max-content;
}

.meeting-user-name{
    width: max-content;
}

.audio-bar{
    width: 25em;
    height: .25em;
    border-radius: 5px;
    background-color: #417505;
}

.audio-time {
    width: 3em;
    margin-left: .75em;
    font-family: Roboto, sans-serif;
    font-size: .7em;
}

.meeting-annotation-image-div{
    margin-top: 1.8%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 0;
    padding-bottom: 35%;
    background-color: #417505;
}

.meeting-annotation-horizontal-line {
    margin-top: 5%;
    width: 100%;
    height: .1em;
    background-color: #417505;
}

.meeting-annotation-horizontal-line-2 {
    margin-top: 2%;
    margin-bottom: 3%;
    width: 100%;
    height: .1em;
    background-color: #417505;
}

.meeting-anotation-yellow-svg-color {
    filter: invert(69%) sepia(85%) saturate(1442%) hue-rotate(344deg) brightness(102%) contrast(92%);
}

.meeting-anotation-yellow-color {
    color: #f5a623;
}

.annotation-circle-div {
    display: block;
    margin-left: 3%;
}

.annotation-circle {
    width: 1em;
    height: 1em;
    border: solid 2px #fff;
    border-radius: 50%;
}

.justified-for-annotation {
    display: flex;
    justify-content: center;
}

.meeting-annotation-name {
    width: max-content;
    margin-left: .75em;
    font-family: Roboto, sans-serif;
    font-size: .8em;
    flex-grow: 1;
    color: white;
}

.meeting-annotation-close-button {
    width: 7.12rem;
    height: 0.96rem;
    padding: 0.5rem 0.564rem 1.2rem 0.5rem;
    border-radius: 5px;
    border: solid 1px grey;
    background-color: #fff;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.01px;
    text-align: center;
    color: #67b017;
    cursor: pointer;
  }

.purple-color-annotation {
    color: #5344c1 !important;
}

.no-max {
    max-width: none !important;
}

.no-margin {
    margin-top: 0 !important;
}

.no-padding {
    padding-left: 0 !important;
}

.horizontal-flex {
    display: flex;
    flex-direction: column;
}