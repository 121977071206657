.act-res-data-form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.act-res-data-form .spacer {
  flex-grow: 1;
}

.act-res-data-form label {
  color: #5344c1;
  display: flex;
  flex-direction: row;
  padding-bottom: 1em;
}

.act-res-data-form label div:first-child {
  width: 10em;
}

.act-res-data-form .list-container {
  /* height: 20em; */
  flex-grow: 4;
}

.act-res-data-form .list-item, 
.act-res-data-form .project-left-content,
.act-res-data-form .project-right-content {
  height: fit-content;
}

.act-res-data-form input[type="file" i] {
  background-color: #d8d3ff;
  color: #5344c1;
  padding: .5em;
  padding-bottom: .45em;
  border-radius: .5em;
  flex-grow: 1;
}

.act-res-data-form input[type="file" i]:hover {
  cursor: pointer;
}

.act-res-data-form .submit-cancel-options {
  width: 100%;
  display: inline-block;
  text-align: center;
  align-items: center;
}

.act-res-data-form .submit-button, .act-res-data-form .cancel-button{
  background-color: #5344c1;
  color: white;
  border-width: 0em;
  padding: .5em;
  border-radius: .5em;
  font-weight: bolder;
  width: 10em;
  text-align: center;
}

.act-res-data-form .submit-button:hover {
  cursor: pointer;
}

.act-res-data-form .submit-button:active {
  background-color: #d8d3ff;
}

.act-res-data-form .cancel-button {
  background-color: red;
  margin-right: 2em;
}

.act-res-data-form .cancel-button:hover {
  cursor: pointer;
}

.act-res-data-form .cancel-button:active {
  background-color: rgba(255, 0, 0, 0.498);
}

