.meeting-options {
  background-color: #c3bbfa;
  padding-left: 0%;
  position: absolute;
  width: 10em;
  top: 60%;
  right: 60%;
  z-index: 1;
  align-items: flex-start;
  border-radius: .15em;
}

.meeting-options.hidden {
    visibility: hidden;
}

.meeting-options.active {
    visibility: visible;
}

.meeting-options .button {
    background-color: transparent;
    border: transparent;
    font-size: 1em;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.959);
  }
  
.meeting .button:active {
    background-color: #d0caff;
    border-width: 0;
}
  