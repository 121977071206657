.display-new-user{
    column-count: 1;
    column-gap: 40px;
    column-width:300px;
}

.display-new-user form input{
    text-align: center;
}

.new-user-items{
    font-size: small;
    color:#5344c1;
}

.disabled-new-user-items{
    font-size: small;
    color:#BAB6D1;
}

.new-user-input-box {
    width:100%;
    padding: 0;
    line-height: 200%;
    border-radius: 10px;
    border: none;
    background-color: #d8d3ff;
    margin-top: 3%;
}

.disabled-new-user-input-box {
    width:100%;
    padding: 0;
    line-height: 200%;
    border-radius: 10px;
    border: none;
    background-color: #F3F2FF;
    margin-top: 3%;
}

.user-type-buttons {
    margin-top: 2em;
    display: inline;
}

.selected-user-type-button {
    width: 50%;
    margin: 0 auto;
    display: inline;
    padding: 0;
    line-height: 200%;
    border-radius: 10px;
    border: none;
    background-color: #d8d3ff;
    margin-top: 3%;
    font-weight: 600;
    color: #5344c1;
}

.user-type-button {
    width: 50%;
    margin: 0 auto;
    display: inline;
    padding: 0;
    line-height: 200%;
    border-radius: 10px;
    border: none;
    background-color: #F3F2FF;
    margin-top: 3%;
    font-weight: 600;
    color: #BAB6D1;
}

.submit-new-user{
    width: 47%;
    margin-left: 3% !important;
    margin: 0 auto;
    display: inline;
    padding: 0;
    line-height: 200%;
    border-radius: 10px;
    border: none;
    margin-top: 3%;
    font-weight: 600;
    background-color: #5344c1;
    color: white;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    box-shadow: none;
}

.cancel-red-color{
    background-color: rgb(235, 73, 73) !important;
    margin-left: 0 !important;
    margin-right: 3% !important;
}