.modal-content.attached-file-modal {
  width: 40em;
}

.modal-header {
  background-color: #d8d3ff;
  border-radius: 0.5em .5em 0em 0em;
  padding: 1em 2em;
}

.modal-header h2 {
  color: #5344c1;
  margin: 0;
}

.modal-header h3 {
  color: #5344c1;
  margin: 0;
  text-align: center;
}

.modal-body {
  flex-grow: 1;
  background-color: white;
  border-radius: 0em 0em .5em .5em;
  display: flex;
  flex-direction: column;
  padding: 1em 2em;
}

.modal-body .data-options {
  color: #5344c1;
  display: flex;
  flex-direction: row;
  padding-bottom: 1em;
}

.data-options .info-type-button {
  padding: .6em;
  border-radius: .5em;
  margin-right: .5em;
}

.data-options .info-type-button:last-child {
  padding: .6em;
  border-radius: .5em;
  margin-right: 0em;
  flex-grow: 4;
}

.data-options .info-type-button.active {
  background-color: #5344c1;
  color: white;
}

.data-options .info-type-button.disabled {
  background-color: #5344c14b;
  color: #5344c171;
}

.modal-body .data-options div:first-child {
  width: 10em;
}

.modal-body .data-form {
  height: 12em;
}

.overlay {
  position: absolute;
  width: 38em;
  height: 22em;
  margin-left: -1em;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 10;
  display: flex;
  justify-content: center;
}

.list-item.attached-file.no-under-line {
  border-bottom-width: 0em !important;
}

.submit-cancel-options {
  display: flex;
  flex-direction: row;
  align-self: center;
}

.submit-cancel-options .cancel {
  background-color: red;
  color: white;
  margin-right: 1em;
  padding: 0.7em;
  border-radius: .3em;
}

.submit-cancel-options .submit {
  background-color: #5344c1;
  color: white;
  padding: 0.7em;
  border-radius: .3em;
}