.on-top {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.on-top a {
  font-weight: lighter;
  text-decoration: underline;
}

.on-top p {
  font-weight: bold;
}

.page-content {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.left-nav {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 20em;
  height: 7em;
  text-align: center;
}

.left-nav span {
  display: flex;
  align-items: center;
  vertical-align: middle;
  line-height: normal;
}

.left-nav-item {
  border-left: white;
  border-left-style: solid;
  border-left-width: 0.3rem;
  align-items: center;
  width: auto;
  height: 50%;
  padding: 1em;
}

.selected-left-nav-item {
  align-items: center;
  background-color: #d8d3ff;
  border-left: #5344c1;
  border-left-style: solid;
  border-left-width: 0.3rem;
  width: auto;
  height: 100%;
  padding: 1em;
  color: black;
}

.right-content {
  font-size: large;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: #5344c1;
  border-style: solid;
  border-width: 0.1rem;
  margin-left: 10%;
  width: 70%;
  padding: 1em;
}

.textbox {
  padding: 5em;
  border-color: #5344c1;
  border-radius: 20em;
}

.bottom-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.purple-button {
  padding: 0.4rem;
  margin: 0.6em;
  width: 10em;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  line-height: normal;
  color: white;
  background-color: #5344c1;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.red-button {
  padding: 0.4rem;
  margin: 0.6em;
  width: 10em;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  line-height: normal;
  color: white;
  background-color: red;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.purple-button-allwidth {
  background-color: #5344c1;
  color: white;
  border: none;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.edit-form-field {
  margin: 2% 5%;
}

.edit-form-field input {
  padding: 0.6em 0.6em;
  font-size: small;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.edit-form-field input button {
  width: 12rem;
  height: 4em;
  padding: 1em 15em;
  margin: 4px 2px;
  font-family: Roboto;
  font-size: small;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  line-height: normal;
  color: white;
  background-color: #5344c1;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.modal-overlay {
  position: absolute;
  z-index: 9;
  top: -20em;
  right: -20em;
  bottom: 0;
  left: 0;
  background-color: rgba(13, 0, 107, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-behavior: unset;
}

.new-meeting-modal-box {
  height: 53.62%;
  width: 87.78%;
  margin-right: 0.1%;
  margin-top: 0.1%;
  margin-bottom: 20%;
  border: 0.1em solid #5344c1;
  background-color: white;
  position: absolute;
  padding: 1em;
  scroll-behavior: unset;
}

.new-meeting-modal {
  height: 53.72%;
  width: 87.88%;
  margin-top: 30rem;
  margin-left: 7rem;
  position: absolute;
  padding: 1em;
  border-radius: 0;
  padding: 0em;
  scroll-behavior: unset;
}

.remove-background {
  background-color: transparent;
  border: none;
  text-align: left;
}

.remove-background:active {
  background-color: transparent;
  color: #4a4a4a;
}

.bottom-buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
}


.purple-button{
    padding: 0.4rem;
    margin: .6em;
    width:10em;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    text-align: center;    
    line-height: normal;
    color: white;
    background-color: #5344c1;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.red-button{
    padding: 0.4rem;
    margin: .6em;
    width:10em;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    text-align: center;    
    line-height: normal;
    color: white;
    background-color: red;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.purple-button-allwidth{
    background-color: #5344c1;
    color:white;
    border:none;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.edit-form-field{
    margin: 2% 5%;
}

.edit-form-field input{
    padding: .6em .6em;
    font-size: small;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.edit-form-field input button{
    width: 12rem;
    height: 4em;
    padding: 1em 15em;
    margin: 4px 2px;
    font-family: Roboto;
    font-size: small;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    text-align: center;    
    line-height: normal;
    color: white;
    background-color: #5344c1;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.modal-overlay-categories {
    position: fixed;
    z-index: 1;
    top: -4.5em;
    right: -7.1em;
    left: 7.1em;
    bottom: 4.5em;
    background-color: rgba(13,0,107,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-behavior: unset;
  }

.modal-overlay-markers {
  position: absolute;
  z-index: 1;
  top: -7em;
  right: -20em;
  bottom: 0;
  left: 0;
  background-color: rgba(13,0,107,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-behavior: unset;
}

@media (max-width: 37.5em) {
  .page-content {
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
  .left-nav {
    flex-direction: row;
    height: auto;
    width: 100%;
    font-size: 0.75em;
  }
  .selected-left-nav-item {
    width: 50%;
  }

  .left-nav-item {
    width: 50%;
  }
  .right-content {
    padding-bottom: 2em;
    width: 100%;
    height: auto;
    margin: 0;
    border-left-width: 0;
    border-right-width: 0;
  }
  .main-content {
    overflow: auto;
  }

  .remove-background {
    text-align: center;
  }
}
