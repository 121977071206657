.links-list {
    padding-left: 0em;
    padding-bottom: .5em;
    font-size: .8em;
    cursor: context-menu;
}

.links-list span.clickable {
    cursor: pointer;
    text-decoration: underline;
    color: #4a4a4a;
}

.links-list span.last {
    font-weight: bold;
    color: #5344c1;
}
